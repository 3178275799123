import { Container } from "react-bootstrap"
import Spinner from "../components/Spinner/Spinner";

export const LoadingPage = () => {
  return (
    <Container>
      <div className="d-flex flex-column justify-content-center align-items-center mt-3 p-3">
        <div className="mb-2">
          <h1>Cargando</h1>
        </div>
        <div className="mb-2">
          <Spinner size='medium' rotate />
        </div>
      </div>
    </Container>
  )
}
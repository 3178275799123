import { create } from 'zustand';
import { createDietSlice } from './creators/diet-store';
import { createResultsSlice } from './creators/results-store';
import { createQuestionnaireSlice } from './creators/questionnaire-store';
import { createUserSlice } from './creators/user-store';
import { AppState } from './interfaces';

// combine all stores into one
export const useStateStore = create<AppState>()((...set) => ({
  // resutls intformation
  ...createResultsSlice(...set),

  // diets information
  ...createDietSlice(...set),
 
  // questionnaire data
  ...createQuestionnaireSlice(...set),

  // user data
  ...createUserSlice(...set),
}));

import { Button } from 'react-bootstrap';

import './KitBanner.scss';

type BannerProps = {
    showBanner: boolean;
    onClick?: () => void;
}

function KitBanner({ showBanner, onClick }: BannerProps) {
    if (showBanner) return (
        <div className="kit-test-banner p-3" id="kit-banner">
        <p className="mb-0">Adquiere un test de ADN y obtén una dieta con tu contexto genético</p>
        <Button
          variant="outline-primary"
          className="banner-button"
          onClick={onClick}
        >
          Comprar test
        </Button>
      </div>
    )
    return <></>
}

export default KitBanner;


export const trackEvent = (event: string, name: string, obj: Object): void => {
  
  const clientWindow = window as any;
  
  if (clientWindow.fbq) {
    clientWindow.fbq(event, name, obj);
  } else {
    console.warn('no fbq pixel tracking function');
  }
  
}

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import {
  Instagram,
  Tiktok,
  Linkedin
} from 'react-bootstrap-icons';

import logo from '../../../assets/images/mynu-white-logo.png';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

export const MynuLandingNavBar = () => {
  const [isTop, setIsTop] = useState(true);

  const handleScroll = () => {
    setIsTop(window.scrollY < 50);
  };
  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <Navbar expand="lg"
      bg={!isTop ? "primary": undefined }
      data-bs-theme="dark"
      fixed="top"
      className={(isTop ? "navbar-landing" : " smaller")}
    >
      <Navbar.Brand className='align-self-center'>
        <img
          alt="Mynu"
          src={logo}
          className={(isTop ? "logo" : "logo-small")}
        />
      </Navbar.Brand>
      
      <Navbar.Toggle aria-controls="landing-navbar-nav" className='border-none color-landing'/>
      
      <Navbar.Collapse>
        <Nav className='links-container' >
          <Nav.Link className='capitalized mynu-nav'  href={"https://mynu.ai/objetivos/"}>Objetivos</Nav.Link>
          <NavDropdown title="LA RUTA MYNU" className='navbar-dropdown'>
            <NavDropdown.Item className='capitalized' href="https://mynu.ai/ciencia-conciencia/">
              Ciencia y Conciencia
            </NavDropdown.Item>
            <NavDropdown.Item className='capitalized' href="https://mynu.ai/la-app/">
              La App / plataforma
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className='capitalized'  href={"https://mynu.ai/a-team/"}>Team</Nav.Link>
          <Nav.Link href={"/buy-kit"}>
            <Button variant="outline-light" className='nav-land-btn capitalized spaced-3'>pedir kit</Button>
          </Nav.Link>
        </Nav>

        <Nav className='links-container' >
          <NavDropdown title="LOGIN/REGISTRO" className='navbar-dropdown'>
            <NavDropdown.Item className='capitalized' href="/login">
              LOGIN
            </NavDropdown.Item>
            <NavDropdown.Item className='capitalized' href="/signup">
              REGISTRO
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>

        <Nav className='links-container flex-r align-items-center justify-content-start' >
          <Nav.Link href={"https://www.instagram.com/mynu.ai/"}>
            <Instagram className='color-landing mx-2'/>
          </Nav.Link>
          <Nav.Link href={"https://www.linkedin.com/company/mynu-ai/"}>
            <Linkedin className='color-landing mx-2'/>
          </Nav.Link>
          <Nav.Link href={"https://mynu.ai"}>
            <Tiktok className='color-landing mx-2'/>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

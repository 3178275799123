import React, { useState } from "react";
import MynuBlueLogo from '../../assets/images/mynu-blue-logo.png';
import { Form } from 'react-bootstrap';
import FormButton from "../../components/FormButton/FormButton";

const { Group, Label, Control } = Form;

interface SendEmailFormProps {
  handleClick(email: string): Promise<void>
}

function SendEmailForm({ handleClick }: SendEmailFormProps) {
  const [email, setEmail] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick(email);
  }

  return (
    <div className="form-container pt-2">
          <div className="text-center d-lg-none pb-3">
            <img src={MynuBlueLogo} className="mobile-logo" alt="" width={200}/>
          </div>
          <h1 className="form-title text-center mb-3">Olvidaste tu contraseña</h1>
              <p className="description fw-bold  text-center">Ingresa tu dirección de correo electrónico y te enviaremos un código de verificación</p>
          <Form onSubmit={handleSubmit} className="mt-4">
            <Group className="mb-2" controlId='verificationEmail'>
              <Label className="mb-1">Email:</Label>
              <Control
                required 
                name="email"
                type="email"
                placeholder="Ej: ejemplo@mail.cl"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Group>
            <div className="text-center">
              <FormButton text="Enviar" isDisabled={!email} />
            </div>
          </Form>
    </div>
  )
}

export default SendEmailForm;

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import allergies from '../../../assets/images/categories/allergies.png';
import body from '../../../assets/images/categories/body.png';
import carbs from '../../../assets/images/categories/carbs.png';
import cardio from '../../../assets/images/categories/cardio.png';
import vitamins from '../../../assets/images/categories/vitamins.png';
import wellness from '../../../assets/images/categories/wellness.png';

import { Check2Circle } from 'react-bootstrap-icons';


export const InfoCategories = () => {
  return (
    <div className="flex-container flex-column align-items-start info-categories">
      <div className='text-container'>
        <div className='sub-text info-text'>DESCUBRE TU PREDISPOSICIÓN GENÉTICA</div>
        <div className='subsub-text info-text'>categorías y subcategorías relacionadas con tu metabolismo</div>
      </div>

      <CustomAccordion />

    </div>
  )
}

export default function CustomAccordion() {
  return (
    <div className='align-self-center'>
      <div className='hidden-mobile'>
        <Tabs
          defaultActiveKey="Asimilación de Carbohidratos"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          {
            content.map((item, index) => {
              return (
                <Tab
                  eventKey={item.title}
                  title={IconComponent(item.icon, item.title)}
                  key={index}
                >
                  <div className='card-holder' >
                    {item.content.map((subItem, subIndex) =>
                      <Card className='m-2'>
                        <div
                          key={subIndex}
                          className="m-2 subsub-text card-item flex-container justify-content-center"
                        >
                          <Check2Circle fontSize={48} className='cat-icon mx-2'/>
                          <div className='px-2'>{subItem}</div>
                        </div>
                      </Card>
                    )}  
                  </div>
                </Tab>
              );
            })
          }
        </Tabs>
      </div>
      <div className='mobile-accordion'>
        <Accordion>
          { content.map((item, index) => {
            return (
              <Accordion.Item eventKey={`${index}`} key={index}>
                <Accordion.Header>{IconComponent(item.icon, item.title)}</Accordion.Header>
                <Accordion.Body>
                  {item.content.map((subItem, subIndex) =>
                    <Card className='m-2'>
                      <div
                        key={subIndex}
                        className="m-2 subsub-text card-item flex-r justify-content-start align-items-center"
                      >
                        <Check2Circle fontSize={48} className='cat-icon mx-2' />
                        <div className='px-2'>{subItem}</div>
                      </div>
                    </Card>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          } )}
        </Accordion>
      </div>
    </div>

  );
}

function IconComponent(icon: string, title: string) {
  return (
    <div className='content-center flex-c' style={{width: "100%"}}>
      <img src={icon} alt={title} className='icon' />
      <div className='category-title content-center'>{title}</div>
    </div>
  )
}


const content = [
  {
    title: "Asimilación de Carbohidratos",
    icon: carbs,
    content: ["Asimilación de Carbohidratos"],
  },
  {
    title: "Intolerancias y Alergias",
    icon: allergies,
    content: ["Enfermedad Celiaca", "Alergia al camarón", "Susceptibilidad a alergias", "Alergia al maní", "Intolerancia a la lactosa"],
  },
  {
    title: "Composición Corporal",
    icon: body,
    content: ["Ganancia de Peso e IMC", "Masa Corporal Magra"],
  },
  {
    title: "Salud Cardiovascular",
    icon: cardio,
    content: ["Presión Arterial", "Colesterol", "Triglicéridos", "Salud Cardiovascular General"],
  },
  {
    title: "Metabolismo de vitaminas",
    icon: vitamins,
    content: ["Vitamina A", "Vitamina D", "Vitamina B12", "Vitamina C", "Acído Fólico", "Vitamina B6"],
  },
  {
    title: "Bienestar",
    icon: wellness,
    content: ["Salud Intestinal", "Tabaco", "Alcohol", "Selenio", "Cobre", "Zinc"],
  },
];

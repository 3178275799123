import { FC, useState, useEffect, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { QuestionnaireCard } from './QuestionnaireCard';

const { Group, Label, Select, Text } = Form;

const NO_SELECTION_ID = '-';
const NO_SELECTION_VALUE = '';

type SelectOption = {
  label: string;
  id: string
}

export type SelectComponentProps = {
  name: string;
  question: string;
  options: SelectOption[];
  required: boolean;
  initialValue?: string;
  updateValue: (value: string) => void;
  isValid?: (value: string) => boolean;
  caveat?: string;
  showInvalid?: boolean;
}

const SelectComponent: FC<SelectComponentProps> = (props: SelectComponentProps) => {
  const {
    name,
    question,
    options,
    required,
    initialValue,
    updateValue,
    isValid,
    caveat,
    showInvalid
  } = props;
  const [selectedId, setSelectedId] = useState<string|null>(null);
  
  useEffect(() => {
    setSelectedId( initialValue ?? null );
  }, [initialValue]);
  
  const checkValidInput = useCallback((): boolean => {
    if (isValid && selectedId) return isValid(selectedId);
    if (!selectedId) return false;
    return selectedId!=='';
  }, [selectedId, isValid]);
  
  const triggerValueUpdate = useCallback( (newId:string) => {
    setSelectedId(newId);
    updateValue(newId);
  }, [updateValue]);
  
  return (<QuestionnaireCard>
    <Group controlId={`select-component-${name}`} key={`select-component-${name}`} className='mb-1'>        
      <Row>
        <Col > <Label className="form-label">{question}</Label> </Col>
          {caveat && 
          <Col>
            <Text className="text-muted">
              {caveat} *
            </Text>
          </Col>
          }
      </Row>
      <Select
        name={name}
        required={required}
        value={options.find((option) => option.id===selectedId)?.label ?? NO_SELECTION_VALUE}
        onChange={(event) => {
          const newValue = event.target.value;
          
          const newId = options.find((option) => option.label===newValue)?.id;
          if (newId) {
            triggerValueUpdate(newId);
          }
        }}
        isInvalid={!checkValidInput() && showInvalid}
      >
        {([{id: NO_SELECTION_ID, label: NO_SELECTION_VALUE}].concat(options)).map(({id, label}) => {
          return (<option key={id} value={label} id={id}>
            {label}
          </option>);
        })}
      </Select>
      
    </Group>
  </QuestionnaireCard>)
}

export default SelectComponent;
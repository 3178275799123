import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { loadUserInfo } from "../../store/loader";
import { useStateStore } from "../../store/store";
import { useCurrentUser } from "../../components/AuthContext";

import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { InfoCircle } from "react-bootstrap-icons";

import { NotFoundPage } from "../../router/404Page";
import MainLayout from "../../components/MainLayout/MainLayout";

import * as t from './types';
import { adviceIndexType, predispositionIndexType } from "../../models";

import { compareStrings, isEmptyOrUndefined } from "../../utils";
import './ResultsExplanation.scss';

const P_SEPARATOR_REGEX = /\.\s+/g;

export const ResultsExplanation = () => {
  const params = useParams();
  const state = useStateStore();
  const currentUser = useCurrentUser();

  const {
    category,
    subcategory,
    predisposition,
    advice,
    scroll
  } = params;

  let file;
  try {
    file = require(`./arguments/${category}.json`);
  } catch (e) {
    console.log(`File ${category} not found:`, e);
  }

  const parsedCategoryData: t.categoryType = JSON.parse(JSON.stringify(file));
  const subcategoryData = parsedCategoryData?.subcats?.find((sbcat: t.subcategoryType) => sbcat.subcategory_name === subcategory);

  let parsedPredisposition = predisposition as predispositionIndexType;
  let parsedAdvice = advice as adviceIndexType;

  if (subcategoryData && parsedPredisposition === 'high'
    && !(Object.keys(subcategoryData.predisposition).includes(parsedPredisposition))) {
    parsedPredisposition = 'medium';
  }

  if (subcategoryData && parsedAdvice === 'avoid'
    && Object.keys(subcategoryData.predisposition[parsedPredisposition]).length > 0
    && !isEmptyOrUndefined(subcategoryData.predisposition[parsedPredisposition], 'obj')
    && !(Object.keys(subcategoryData.predisposition[parsedPredisposition].recomendations).includes(parsedAdvice))
  ) {
    parsedAdvice = 'reduce';
  } else if (
    subcategoryData && parsedAdvice.includes('prefer')
  ) {
    parsedAdvice = 'prefer';
  }

  useEffect(() => {
    if (scroll && subcategoryData && !isEmptyOrUndefined(subcategoryData, 'obj')) {
      const scrollItems = subcategoryData
        .predisposition[parsedPredisposition].recomendations[parsedAdvice]?.food_groups
        .map(f => f.name)

      try {
        if (scrollItems && scrollItems.length > 0) {
          const parsedScroll = scrollItems.find(food => {
            if (food) {
              try {
                return compareStrings(scroll, food, 0.95);
              } catch (e) {
                return false;
              }
            }
            return false;
          })
          const foodToScroll = document.getElementById(parsedScroll ? parsedScroll : scroll)
          if (foodToScroll) {
            const offsetY = foodToScroll.offsetTop;
            const offsetX = foodToScroll.offsetWidth;
            window.scrollTo({
              top: offsetY,
              left: offsetX,
              behavior: 'smooth'
            })
          }
        }
      } catch (e) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll]);

  useEffect(()=> {
    if(currentUser){
      loadUserInfo(state, currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (!parsedCategoryData || !subcategoryData || !predisposition || !advice ?
    <NotFoundPage />
    :
    <MainLayout>
      <div className='background-container pt-4'>
        <Container className="main-container">
          <div className="p-4 header-container">
            <div>
              Conoce más sobre tu recomendación nutricional para tu {subcategoryData.name}
            </div>
          </div>
          <RenderSubcategory
            subcategory={subcategoryData}
            predisposition={parsedPredisposition}
            advice={parsedAdvice}
          />
        </Container>
      </div>
    </MainLayout>
  )
}

const RenderSubcategory = (
  { subcategory,
    predisposition,
    advice
  }: {
    subcategory: t.subcategoryType,
    predisposition: predispositionIndexType,
    advice: adviceIndexType
  }
) => {
  if (!subcategory) return null;
  const subcategoryDescription = subcategory.description;
  const subcategoryAdvice = subcategory.predisposition[predisposition] as t.predispositionContent;
  const subcategoryAdviceType = subcategoryAdvice.recomendations[advice] as t.recomendationType;
  const description = subcategoryAdviceType?.description ?? "";
  return (
    <div>
      <div className="p-4">
        <h5>{subcategoryDescription.length > 0 ? subcategoryDescription : description}</h5>
      </div>
      {subcategoryAdviceType.food_groups?.map((group: t.foodGroupType, i: number) => {
        return <RenderFoodGroup key={i} foodGroup={group} />
      })}
    </div>
  )
}

const RenderFoodGroup = ({ foodGroup }: { foodGroup: t.foodGroupType }) => {

  if (!foodGroup) return null;
  const {
    name,
    description,
    question,
    answer,
    question2,
    answer2,
    foods,
    foods2,
    tip
  } = foodGroup;

  return (
    <Card className={"card-element mb-4"} id={name}>
      <Card.Header>
        <RenderInfo foodGroup={foodGroup} />
      </Card.Header>

      <Card.Body>
        <Accordion defaultActiveKey="0" >
          <Accordion.Item eventKey="0" className="item-results-expl">
            <Accordion.Header>
              <span className="fw-bold">Descripción</span>
            </Accordion.Header>
            <Accordion.Body >
              <ul>
                {description &&
                  description
                    .trim()
                    .split(P_SEPARATOR_REGEX).map((par: string, ix: number) =>
                      (par.length > 0) && <li key={par + ix}><p>{par.trim()}</p></li>
                    )
                }
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {
            foods?.length > 0 &&
            <>
              <Accordion.Item eventKey="1"  className="item-results-expl">
                <Accordion.Header><span className="fw-bold">{question ? question : 'Ejemplos'}</span> </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {answer && answer.length > 0 &&
                      answer
                        .trim()
                        .split(P_SEPARATOR_REGEX)
                        .map((a: string, ix: number) => (a.length > 0) && <li key={a + ix}>{a.trim()}</li>)
                    }
                  </ul>
                  {(foods.length < 7) && foods.some(f => f.includes(":")) ?
                    <RenderFoods foods={foods} />
                    : <p>{foodGroup.foods.join(", ")}</p>
                  }
                  {
                    tip && tip?.length > 0 &&
                    <div className="d-flex flex-row align-items-center">
                      <div>
                        <InfoCircle className="info-icon" size={"1rem"} />
                      </div>
                      <div className="info-text">
                        Tip: {tip}
                      </div>
                    </div>
                  }
                </Accordion.Body>
              </Accordion.Item>
              {question2 && question2.length > 0 && foods2 && foods2.length > 0 &&
                <Accordion.Item eventKey="2"  className="item-results-expl">
                  <Accordion.Header><span className="fw-bold">{question2}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {answer2 && answer2.length > 0 &&
                        answer2
                          .trim()
                          .split(P_SEPARATOR_REGEX)
                          .map((a: string, ix: number) => (a.length > 0) && <li key={a + ix}>{a.trim()}</li>)
                      }
                    </ul>
                    {(foods2.length < 7) && foods2.some(f => f.includes(":")) ?
                      <RenderFoods foods={foods2} />
                      :
                      <p>{foodGroup?.foods2 && foodGroup.foods2.join(", ")}</p>
                    }
                  </Accordion.Body>
                </Accordion.Item>
              }
            </>
          }
        </Accordion>
      </Card.Body>
    </Card>
  )
}

const RenderInfo = ({ foodGroup }: { foodGroup: t.foodGroupType }) => {
  const { name, image } = foodGroup;
  let imgSrc;
  try {
    const parsedImageName = image.toLowerCase().replaceAll(',', "").replaceAll(" ", "_");
    imgSrc = require(`../../assets/images/results_explanation/${parsedImageName}`);
  } catch (e) {
    console.log('Eror: cannot find image', e)
  }

  return (
    <div className="subcat-header">
      {imgSrc &&
        <div className="img-container" style={{ backgroundImage: `url(${imgSrc})` }} />
      }
      <div className="overlay">{name}</div>
    </div>
  )
}

const RenderFoods = ({ foods }: { foods: string[] }) => {
  let parsedFoods: {
    foodName: string,
    foodDescription: string[]
  }[] = foods.map((food: string) => {
    if (food.includes(":")) {
      const [foodName, foodDescription] = food.split(":");
      return ({
        foodName: foodName,
        foodDescription: foodDescription.split(",").map((desc: string) => desc.trim())
      })
    } else {
      return ({
        foodName: "",
        foodDescription: [food],
      })
    }
  })

  return (
    <div className="foods-container">
      {parsedFoods.map((food: { foodName: string, foodDescription: string[] }, i: number) => {
        return (
          <div key={food.foodName + i} className="food-item">
            {
              food.foodName &&
              <div className="food-name">{food.foodName}: </div>
            }
            {food.foodName ? food.foodDescription.join(", ")
              : <div className="food-name">{food.foodDescription.join(", ")}</div>
            }
          </div>
        )
      })}
    </div>
  )
}

import { User } from "./user-model";

export type DisplayChatMessage = {
  role: 'system' | 'user' | 'assistant';
  content: string;
  createdAt?: number;
  type?: string;
  messageId?: string;
  conversationId?: string;
  rate?: MessageRate;
}


export interface MessagesListProps {
  messages: DisplayChatMessage[];
  currentUser: User | null;
  dots: string;
  handleSendMessageDay: (day: string) => void; // Replace 'string' with the appropriate type for day
  toggleSubscriptionPlan: () => void;
  messageRate: (rate: MessageRate, message: DisplayChatMessage) => void
}

export type MessagesResponse = {
  foundMessages: DisplayChatMessage[];
  allFetched: boolean;
}

export enum MessageRate {
  LIKE = 'like',
  DISLIKE = 'dislike'
}
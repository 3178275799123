import { Card, Col, Container, Row } from "react-bootstrap";
import SubscriptionImage from '../../assets/images/subscription-image.png';
import SubscriptionImageMobile from '../../assets/images/subscription-image-mobile.png';
import SubscriptionIcon from '../../assets/icons/subscription-icon.svg';

type SubscriptionSectionProps = {
  showSubscriptionCard: boolean;
  onClick?: () => void;
}

function SubscriptionSection({ showSubscriptionCard, onClick }: SubscriptionSectionProps) {
  if(showSubscriptionCard){
   return (
      <Card className="mb-4 home-card">
        <Container>
          <Row>
            <Col lg="auto" xs={12} className="px-0">
              <img src={SubscriptionImage} alt="" className="banner-premium d-none d-lg-block"/>
              <img src={SubscriptionImageMobile} alt="" className="home-section-image d-flex d-lg-none"/>
            </Col>
            <Col className="pt-3 pb-lg-0">
              <h2 className="home-title subtitle">
                <img src={SubscriptionIcon} alt="" className="me-3"/>
                ¿Por qué ser premium?
              </h2>
              <p className="home-text mt-3 text-center text-lg-start">
                Podrás acceder a dietas personalizadas ilimitadas ajustadas a tus hábitos y mantenerte en contacto constante con tu asesor nutricional Numy. 
                <br/>
                ¡Maximiza tu salud y bienestar en cada comida!
              </p>
            </Col>
          </Row>
        </Container>
      </Card>
   )
  }
  return <></>
}

export default SubscriptionSection;

import { Accordion } from "react-bootstrap";
import { formatCLP } from "../../../../utils/common";
import { DISCOUNT_PRICE_CLP, KIT_PRICE_CLP } from "../../constants";

export type OrderConfirmationProps = {
  formData: any;
  submitOrderData(data: any): Promise<void>;
  changeStep(data: any, variation: 'forwards' | 'backwards'): void;
}

export default function ConfirmationStep(props: OrderConfirmationProps) {

  const { formData } = props;
  return (
    <>
      <div className="confirmation-step mobile-hidden">
        <div className="info-section mb-2">
          {FieldData({ label: 'Nombre', value: `${formData.firstName} ${formData.lastName}` })}
          {FieldData({ label: 'Telefono', value: formData.phoneNumber })}
        </div>

        <div className="address-section mb-2">
          <div className="section-header">Información de Envío</div>
          {FieldData({ label: 'Dirección', value: formData.address })}
          {FieldData({ label: 'Info adicional', value: formData.address2 })}
          {FieldData({ label: 'Región', value: "Metropolitana" })}
          {FieldData({ label: 'Ciudad', value: "Santiago" })}
          {FieldData({ label: 'Comuna', value: formData.province })}
        </div>

        <div className="order-section">
          <div className="section-header">Pago</div>
          {FieldData({ label: 'Kit(s)', value: formData.kitQuantity })}
          {FieldData({ label: 'Subtotal', value: <b>{formatCLP(KIT_PRICE_CLP * formData.kitQuantity)}</b> })}
          <div className="field">
            <div className="label">Total a pagar</div>
            <div className="value color-purple mynu-bold"><b>{formatCLP(DISCOUNT_PRICE_CLP * formData.kitQuantity)}</b></div>
          </div>
        </div>
      </div>
      <Accordion flush className="confirmation-step mx-0 desktop-hidden" defaultActiveKey={"0"}>
        <Accordion.Item eventKey="0" className="m-1">
          <Accordion.Header className="info-section">
            <div className="section-header">Información de Contacto</div>
          </Accordion.Header>
          <Accordion.Body className="info-section">
            {FieldData({ label: 'Nombre', value: `${formData.firstName} ${formData.lastName}` })}
            {FieldData({ label: 'Email', value: formData.email })}
            {FieldData({ label: 'Telefono', value: formData.phoneNumber })}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="m-1">
          <Accordion.Header className="address-section">
            <div className="section-header">Información de Envío</div>
          </Accordion.Header>
          <Accordion.Body className="address-section">
            {FieldData({ label: 'Dirección', value: formData.address })}
            {FieldData({ label: 'Info adicional', value: formData.address2 })}
            {FieldData({ label: 'Región', value: "Metropolitana" })}
            {FieldData({ label: 'Ciudad', value: "Santiago" })}
            {FieldData({ label: 'Comuna', value: formData.province })}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="m-1">
          <Accordion.Header className="order-section">
            <div className="section-header">Pago</div>
          </Accordion.Header>
          <Accordion.Body className="order-section">
            {FieldData({ label: 'Kit(s)', value: formData.kitQuantity })}
            {FieldData({ label: 'Subtotal', value: <b>{formatCLP(KIT_PRICE_CLP * formData.kitQuantity)}</b> })}
            <div className="field">
              <div className="label">Total a pagar</div>
              <div className="value color-purple mynu-bold"><b>{formatCLP(DISCOUNT_PRICE_CLP * formData.kitQuantity)}</b></div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}

function FieldData({ label, value }: { label: string, value: JSX.Element | string }) {
  return (
    <div className="field">
      <div className="label">{label}</div>
      <div className="value gray">{value}</div>
    </div>
  )
}
const prefer_text = 'Basar tu dieta en estos grupos de alimento te ayudará a llevar tu salud y bienestar al máximo. **Prioriza aquellos grupos de alimentos del nivel superior**.'
const reduce_text = 'Estos están asociados con algunas de tus características feno o genotípicas. **Consume estos grupos de alimentos en menor frecuencia**.'
const avoid_text = 'Estos grupos de alimentos están vinculados estrechamente con tus caracteristicas fenotípicas o con predisposiciones de alto riesgo según tu genética. **Intenta consumirlos de manera ocasional y evita al máximo los del nivel superior**.'

export const diet_summary_texts: any = {
    pref: {
        description: prefer_text,
        subtitle: "BASA TU DIETA EN"
    },
    red: {
        description: reduce_text,
        subtitle: 'CONSUME EN MENOR FRECUENCIA'
    },
    avoid: {
        description: avoid_text,
        subtitle: 'EVÍTALOS',
        subsubtitle: 'EVÍTALOS AL MÁXIMO'
    }
}

export const summary_index = [
    {"cat": "allergies", "sbcat": "allergies_shrimp", "kind": "allergies", "questionnaire_id": "shrimp", "summary": "Evita al maximo", "pref": "", "red": "", "avoid": "Camarón \ud83e\udd90, Crustáceos \ud83e\udd90\ud83e\udd9e\ud83e\udd80"},
    {"cat": "allergies", "sbcat": "allergies_peanut", "kind": "allergies", "questionnaire_id": "peanut", "summary": "Evita al maximo", "pref": "", "red": "", "avoid": "Maní \ud83e\udd5c, Frutos secos \ud83e\udd5c\ud83c\udf30\ud83e\uded8"},
    {"cat": "allergies", "sbcat": "allergies_lactose", "kind": "allergies", "questionnaire_id": "lactose", "summary": "Evita al maximo", "pref": "Lácteos sin lactosa \ud83e\udd5b\ud83e\uddc0, Alimentos fermentados \ud83e\uddc5\ud83e\udd5b\ud83e\udeda", "red": "", "avoid": ""},
    {"cat": "allergies", "sbcat": "allergies_celiac", "kind": "allergies", "questionnaire_id": "gluten", "summary": "Evita al maximo", "pref": "Harinas y cereales sin gluten \ud83e\uded3\ud83c\udf3d\ud83c\udf5a, Alimentos procesados sin gluten \ud83e\uded3\ud83c\udf5e\ud83e\udd6b", "red": "", "avoid": "Trigo y derivados \ud83e\udd56\ud83c\udf5e\ud83c\udf3e, Avena no certificada sin gluten \ud83e\udd63"},
    {"cat": "allergies", "sbcat": "", "kind": "allergies", "questionnaire_id": "fish", "summary": "Evita al maximo", "pref": "", "red": "", "avoid": "Carnes blancas, magras, pescados y mariscos \ud83c\udf57\ud83d\udc1f\ud83e\udd9e, Pescados grasos y mariscos \ud83d\udc20\ud83e\udd90\ud83e\uddaa"},
    {"cat": "allergies", "sbcat": "", "kind": "allergies", "questionnaire_id": "egg", "summary": "Evita al maximo", "pref": "", "red": "", "avoid": "Huevos \ud83c\udf73"},
    {"cat": "corbohydrates", "sbcat": "corbohydrates", "kind": "med", "questionnaire_id": "diabetes", "summary": "Evita", "pref": "Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3, Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e, Frutas enteras \ud83e\uded0\ud83c\udf51\ud83c\udf52, Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2, Canela\ud83e\udeb5, Vinagre\ud83e\uded9", "red": "", "avoid": "Cereales refinados \ud83e\udd63\ud83c\udf5e\ud83e\udd50, Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d"},
    {"cat": "cardiovascular", "sbcat": "cardiovascular_colesterol", "kind": "med", "questionnaire_id": "hypercholesterolemia", "summary": "Evita", "pref": "Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90, Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3, Frutas y verduras \ud83e\udd6c\ud83e\udd55\ud83c\udf4c, Aceite de oliva o canola \ud83e\uded2, Lácteos con fitoesteroles \ud83e\udd5b / Lácteos sin lactosa con fitoesteroles \ud83e\udd5b", "red": "", "avoid": "Carnes rojas altas en grasa \ud83e\udd53\ud83e\udd69\ud83c\udf56, Embutidos \ud83c\udf2d\ud83e\udd53\ud83c\udf56"},
    {"cat": "cardiovascular", "sbcat": "cardiovascular_triglicerides", "kind": "med", "questionnaire_id": "hypercholesterolemia", "summary": "Evita", "pref": "Verduras\ud83e\udd57\ud83e\udd59\ud83c\udf2e, Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90, Carbohidratos complejos \ud83e\uded8\ud83e\udd55\ud83e\uded3, Grasas insaturadas \ud83e\udd51\ud83c\udf3b\ud83e\uded2", "red": "", "avoid": "Azúcar y productos dulces\ud83c\udf6b\ud83e\uddc3\ud83c\udf6d, Alcohol \ud83e\udd43\ud83c\udf77\ud83c\udf7a"},
    {"cat": "cardiovascular", "sbcat": "cardiovascular_bloodpressure", "kind": "med", "questionnaire_id": "hypertension", "summary": "Evita", "pref": "Alimentos no procesados \ud83c\udf47\ud83c\udf57\ud83e\udd59, Aceite de oliva o canola \ud83e\uded2, Alimentos ricos en Omega-3 \ud83e\udd5c\ud83d\udc20\ud83e\udd90, Alimentos altos en antioxidantes \ud83c\udf4a\ud83e\uded0\ud83e\udd6c", "red": "", "avoid": "Ultraprocesados altos en sodio\ud83e\udd6b\ud83c\udf5f\ud83c\udf2d, Concentrados altos en sodio \ud83e\udd6b\ud83c\udf5c\ud83e\uddc2"}
]
import React from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import './Questionary.scss';

interface ExitModalProps {
  show: boolean;
  onClose: () => void;
  onExit: (save: boolean) => void;
}

const QuestionaryExitModal: React.FC<ExitModalProps> = ({ show, onClose, onExit }) => {
  return (
    <Modal size='lg' show={show} onHide={onClose} className='container-exit-modal'>
      <Modal.Header className="header-exit-modal close" closeButton />
      <Modal.Body>
        <div className="exit-container d-flex center">
          <h1 className="text-title-exit-modal">
            ¿Estás seguro de salir del perfilamiento?
          </h1>
          <p>
            Recuerda que podrás acceder desde el inicio.
          </p>
          <Col>
            <Row>
              <Col md={6}>
                <Button onClick={() => onExit(false)} className="continue-button m-3" variant="outline-primary">Salir</Button>
              </Col>
            </Row>
          </Col>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionaryExitModal;

import { FC } from "react";


export type QuestionnaireSectionHeaderProps = {
  title: string;
  subtitle: string;
  numerator: number;
  denominator: number;
}

const QuestionnaireHeader: FC<QuestionnaireSectionHeaderProps> = (props: QuestionnaireSectionHeaderProps) => {
  const {
    title,
    subtitle,
    numerator,
    denominator
  } = props;
  
  return (<div className="">
    <div className='text-pages text-center'>
      <label className="text-primary">{numerator}</label>/{denominator}</div>
    <h1 className="questionary-title text-center">{title}</h1>
    <p className="questionary-subtitle">{subtitle}</p>
  </div>)
}

export default QuestionnaireHeader;
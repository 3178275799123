import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Meal } from "../../../../models/diet-model";
import { changeMenuByIngredient } from "../../../../services/diet";
import { FadeLoader } from "react-spinners";
import { Subscription, User } from '../../../../models';
import { validSubscription } from '../../../../utils';
import Ingredient from "./Ingredient";
import { useStateStore } from "../../../../store/store";


type MealCardProps = {
  meal: Meal,
  index: number,
  day: string,
  requestMealOptions: (day: string, menuName: string) => void,
  loadDiet: () => Promise<void>,
  setNotificationText: (message: string) => void,
  showSubscriptionModal: () => void,
  currentUser: User | null,
  setOpenDietAccordionDay: (day: string) => void
}

function MealCard(props: MealCardProps) {
  const store = useStateStore();
  const { setIsLoadingDiet } = store;
  const {
    meal,
    index,
    day,
    loadDiet,
    setNotificationText,
    showSubscriptionModal,
    currentUser,
    setOpenDietAccordionDay
  } = props;
  const [selectedIngredient, setSelectedIngredient] = useState('');
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    if (currentUser && currentUser?.subscription) {
      setSubscription(currentUser?.subscription)
    }
  }, [currentUser])

  const changeIngredient = async (day: string, menuName: string, ingredient: string, ingredientHistory: string, reason: string) => {
    if (subscription && !validSubscription(subscription)) {
      showSubscriptionModal()
    }
    else {
      try {
        setSelectedIngredient(ingredient);
        const userToken = localStorage.getItem('userToken');
        if (userToken) {
          const params = {
            day,
            menuName,
            ingredient,
            ingredientHistory,
            userToken,
            reason
          }
          setOpenDietAccordionDay(day);

          await changeMenuByIngredient(params);
          await loadDiet();
        }
        setSelectedIngredient('');
        setNotificationText('Tu dieta se ha actualizado exitosamente')
      } catch (error) {
        setNotificationText('Error al actualizar tu dieta. Vuelve a intentarlo');
        setSelectedIngredient('');
        setIsLoadingDiet(false);
      }
    }
  }

  const handleChangeIngredient = (food: string, ingredientHistory: string[], reason: string) => {
    changeIngredient(day, meal.name, food, ingredientHistory.join(', '), reason);
  }

  return (
    <>
      <Card className={`meal-card ${meal.name.toLowerCase()}`} id={`meal-card-${meal.name.toLowerCase()}`}>
        <Card.Header className="pb-2">
          <div className="m-1 meal-icon" aria-hidden="true" />
          <div className="m-1 w-75 meal-title text-capitalize font-avenir-bold" key={`${meal.name}-${index}`}>
            {meal.name}
          </div>
        </Card.Header>
        <Card.Body className="m-1 p-0">
          {meal.foods.map((food: string, id: number) => {
            return (
              <Container key={food}>
                {food === selectedIngredient ? (
                  <div className="d-flex justify-content-center py-2">
                    <FadeLoader
                      color="#C0BCBC"
                      loading={true}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      height={6}
                      margin={-8}
                      width={3}
                      radius={1}
                    />
                  </div>
                ) : (
                  <Ingredient
                    food={food}
                    selectedIngredient={selectedIngredient}
                    handleChangeIngredient={handleChangeIngredient}
                    name={`${meal.day}-${meal.name.toLowerCase()}-${id}`}
                  />
                )}
              </ Container>)
          })
          }

        </Card.Body>
      </Card>

    </>
  )
}

export default MealCard;

import { useCurrentUser } from "../../components/AuthContext";
import MainLayout from "../../components/MainLayout/MainLayout";
import { NotAuthorizedPage } from "../../router/403NoLayoutPage";

export type AdminPageProps = {
}


function AdminPage(props: AdminPageProps) {
  const currentUser = useCurrentUser();

  if(!(currentUser?.admin)){
    return <NotAuthorizedPage />
  }
  console.log(currentUser)
  return (
    <MainLayout>
      hola
    </MainLayout>
  )
}

export default AdminPage;
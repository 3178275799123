import { useNavigate } from "react-router-dom";
import { MynuButtonComponent } from "./ButtonComponent"

export const CallToAction = () => {
  const navigate = useNavigate();
  return (
    <div className="call-to-action flex-c content-center p-3">
      <div className="main-text cta-title spaced-2 m-2 ">Precio especial de lanzamiento</div>
      <div className="main-text cta-old-price m-2">
        {(119990).toLocaleString("es-CL", {style:"currency", currency:"CLP"})}
      </div>

      <div className="flex-c content-center">
        <div className="cta-new-price-container px-4 flex-c content-center">
          <div className="main-text cta-new-price m-2">
            {(99990).toLocaleString("es-CL", {style:"currency", currency:"CLP"})}
          </div>
        </div>
        <div className="cta-new-price-subtitle">
          pocas unidades
        </div>
      </div>

      <div className="call-to-action-button m-2">
        <MynuButtonComponent text="COMPRA TU KIT PARA COMENZAR" onClick={() => navigate('/buy-kit')} />
      </div>

  </div>
  )
}
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useCurrentUser } from "../../components/AuthContext";
import SubscriptionBadge from "../../components/SubscriptionBadge/SubscriptionBadge";
import KitBanner from '../../components/KitBanner/KitBanner'
import './SettingsPage.scss';
import MailBoxImage from '../../assets/images/mailbox.svg';
import { Subscription } from '../../models';
import { validSubscription } from '../../utils';
import SubscriptionPlanModal from '../../components/SubscriptionPlanModal/SubscriptionPlanModal';
import { MoreInfoKit } from "../../components/MoreInfoComponent/MoreInfoKit";
import { TableHeaderComponent } from "./TableHeaderComponent";
import { TableRowComponent } from "./TableRowComponet";

function SettingsPage() {
  const currentUser = useCurrentUser();
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showKitBanner, setShowKitBanner] = useState(false);
  const [showSubscriptionPlanModal, setShowSubscriptionPlanModal] = useState(false);
  const [showSubscriptionCard, setshowSubscriptionCard] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState('');

  useEffect(() => {
    if (currentUser && currentUser?.subscription) {
      const sub = currentUser.subscription;
      setSubscription(sub);

      // check subscription to set user text
      setSubscriptionName(getSubscriptionText(sub));

      // Check for freemium subscription
      if (sub.type === 'freemium') {
        setshowSubscriptionCard(true);
      }
      // Check for non-valid subscription
      if (!validSubscription(sub)) {
        setshowSubscriptionCard(true);
      }
      // Check for non-premium subscription
      if (sub.type !== 'premium') {
        // Check for non-premium subscription to set kit card
        setShowKitBanner(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser,]);

  const navigate = useNavigate();
  const goBuyKit = () => navigate('/buy-kit');

  const onHideModal = async () => {
    setShowModal(false);
  }

  const toggleSubscriptionPlan = () => {
    setShowSubscriptionPlanModal(!showSubscriptionPlanModal)
  }

  const getSubscriptionText = (subscription: Subscription) => {
    let auxSubscriptionText = 'Suscripción ';
    if (subscription.frequency === 3) auxSubscriptionText += ' Mensual';
    else if (subscription.frequency === 4) auxSubscriptionText += ' Anual';

    if (!validSubscription(subscription)) return 'Sin suscripción activa';
    else if (subscription.type === 'freemium') return 'Suscripción Freemium';
    else if (subscription.type === 'premium') auxSubscriptionText += ' Premium';

    if (validSubscription(subscription) && (subscription.frequency === 3 || subscription.frequency === 4)) auxSubscriptionText += ' / se renueva automáticamente';

    return auxSubscriptionText;
  }

  return (
    <MainLayout>
      <KitBanner showBanner={showKitBanner} onClick={goBuyKit} />
      <Container fluid className="page pt-2 pt-lg-4 px-0">
        <Container className="page-container">
          <Card className="p-4 mb-4 home-card">
            <Row>
              <Col sm="10">
                <h1 className="home-title">Mi Perfil</h1>
              </Col>
              <SubscriptionBadge subscription={subscription} />
            </Row>
          </Card>
          {showSubscriptionCard && (
            <MoreInfoKit />
          )}
          <Card className="p-4 mb-4 home-card">
            <TableHeaderComponent text="Mis datos personales" />

            <TableRowComponent title={'Nombres'} value={currentUser?.givenName} hr={true} />
            <TableRowComponent title={'Apellidos'} value={currentUser?.familyName} hr={true} />
            <TableRowComponent title={'Celular'} value={currentUser?.phoneNumber} hr={true} />
            <TableRowComponent title={'Correo electrónico'} value={currentUser?.email} hr={true} />
            <TableRowComponent title={'Contraseña'} value={'*********************'} />
          </Card>

          <Card className="p-4 mb-4 home-card">
            <TableHeaderComponent text="Mi plan Mynu" />
            <TableRowComponent id='subscription' title="Tipo de suscripción" value={subscriptionName} hr={true} />
            <TableRowComponent
              title="Cambiar método de pago"
              value={
                <div onClick={() => {
                  setTitle('Actualiza tu método de pago y sigue disfrutando de nuestros servicios.');
                  setDescription('Para modificar el método de pago asociado a tu cuenta, por favor envía un correo electrónico a pagos@mynu.ai con la siguiente información:');
                  setShowModal(true);
                }} className="text-link">Cambiar</div>
              }
              hr={true}
            />
            <TableRowComponent
              title="Suscripción"
              value={
                <div onClick={() => {
                  setTitle('Solicitar dar de baja la suscripción');
                  setDescription('Para modificar tu suscripción, envía un correo con tus datos a pagos@mynu.ai. Nos encargaremos de actualizar tus preferencias y te notificaremos una vez completado el cambio.');
                  setShowModal(true)
                }} className="text-link">Dar de baja la suscripción</div>
              }
            />
          </Card>

          <Modal
            show={showModal}
            onHide={onHideModal}
            aria-labelledby="contained-modal-title-vcenter"
            className="text-center settings-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton className="border-bottom-0 mx-3 mt-3"></Modal.Header>
            <Modal.Body className="pt-0 pb-4">
              <img src={MailBoxImage} alt="" />
              <Modal.Title id="contained-modal-title-vcenter" className="modal-title mt-3">
                <h1>{title}</h1>
              </Modal.Title>
              <p className="mt-4">
                {description}
              </p>
              <p className="text-data">Nombres, correo electrónico, celular y RUT.
                <br />(La misma información de tu cuenta en Mynu)</p>
              <Button className="fw-bold px-3 mt-2 button-accept" onClick={onHideModal}>
                Aceptar
              </Button>
            </Modal.Body>
          </Modal>
          <SubscriptionPlanModal
            showModal={showSubscriptionPlanModal}
            subscription={subscription}
            onHide={toggleSubscriptionPlan}
          />
        </Container>
      </Container>
    </MainLayout>
  )
}

export default SettingsPage;

import { StateCreator } from 'zustand';
import { QuestionnaireData, QuestionnaireOptions } from '../../models';
import { AppState, QuestionnaireStore } from '../interfaces';

export const createQuestionnaireSlice: StateCreator<
  AppState,
  [],
  [],
  QuestionnaireStore
> = (set, get) => ({
  questionnaireData: null,
  setQuestionnaireData: (d: QuestionnaireData) => set(() => ({ questionnaireData: d })),
  isLoadingQuestionnaire: false,
  setIsLoadingQuestionnaire: (l: boolean) => set(() => ({ isLoadingQuestionnaire: l })),
  questionnaireOptions: null,
  setQuestionnaireOptions: (d: QuestionnaireOptions) => set(() => ({ questionnaireOptions: d })),
  isLoadingQuestionnaireOptions: false,
  setIsLoadingQuestionnaireOptions: (l: boolean) => set(() => ({ isLoadingQuestionnaireOptions: l })),
  hasQuestionnaireData: () => !!get().questionnaireData,
});
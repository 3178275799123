import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

// state & context
import { useCurrentUser } from '../AuthContext';
import { useStateStore } from '../../store/store';
import { loadUserData } from '../../store/loader';

// utils
import { validSubscription } from '../../utils';

// my components
import MynuTooltip from '../Tooltip/Tooltip';

// assets
import './AppNavbar.scss';
import LockIcon from '../../assets/images/lock-icon.svg';
import MynuLogo from '../../assets/images/mynu-y-icon.svg';
import NavbarLogo from '../../assets/images/mynu-navbar-logo.svg';
import {
  messages,
  RESULTS_DISABLED,
  NUMY_DISABLED,
  DIETS_DISABLED,
} from './constants';

function AppNavbar() {
  const store = useStateStore();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { hasQuestionnaireData, hasResults } = useStateStore();
  const [isSubscriptionBanner, setSubscriptionBanner] = useState(true);

  const resultsAvailable = () => {
    return currentUser && hasResults() && currentUser?.resultsDate
  }

  useEffect(() => {
    if (currentUser && currentUser?.subscription) {
      if (!validSubscription(currentUser?.subscription)) {
        setSubscriptionBanner(false)
      }
    }
  }, [currentUser]);

  const signOff = async () => {
    localStorage.removeItem('userToken');
    await loadUserData(store);
    navigate('/login');
  };

  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" style={{ background: 'white' }} >
      <Container>
        <Navbar.Brand>
          <Link to='/' >
            <img className="ms-5" src={NavbarLogo} alt="" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto mx-auto">
            <Link
              className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/' && 'active-navbar-link'}`}
              to="/"
            >
              Inicio
            </Link>
            <span className="d-flex align-items-center justify-content-start">
              <span
                className="d-inline-block"
              >
                {hasQuestionnaireData() && resultsAvailable() && !DIETS_DISABLED ?
                  <Link to="/diets"
                    className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/diets' && 'active-navbar-link'}`}
                  >
                    <span id="diet">Mi Menú</span>
                  </Link>
                  :
                  <MynuTooltip
                    description={
                      hasQuestionnaireData() && !resultsAvailable()
                        ? messages.DIETS_DISABLED_NEED_RESULTS_MSG
                        : !hasQuestionnaireData() ? messages.DIETS_DISABLED_COMPLETE_QUESTIONNAIRE_MSG
                          : messages.OUT_OF_SERVICE_MSG
                    }
                    position='bottom'
                  >
                    <div className='nav-link mx-4 px-0 navbar-links'>
                      <span className='disabled-link'>Mi Menú</span>
                      <img src={LockIcon} alt="" className="ms-3" />
                    </div>
                  </MynuTooltip>}
              </span>
            </span>

            <span className="d-flex align-items-center justify-content-start">
              <span className="d-inline-block" >
                {hasQuestionnaireData() && resultsAvailable() && !NUMY_DISABLED ?
                  <Link to="/chat"
                    className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/chat' && 'active-navbar-link'}`}
                  >
                    <span id="numy">{'Numy (Beta)'}</span>
                  </Link>
                  :
                  <MynuTooltip
                    description={
                      hasQuestionnaireData() && !resultsAvailable()
                        ? messages.NUMY_DISABLED_NEED_RESULTS_MSG
                        : !hasQuestionnaireData() ? messages.NUMY_DISABLED_COMPLETE_QUESTIONNAIRE_MSG
                          : messages.OUT_OF_SERVICE_MSG
                    }
                    position='bottom'
                  >
                    <div className='nav-link mx-4 px-0 navbar-links'>
                      <span className='disabled-link'>{'Numy (Beta)'}</span>
                      <img src={LockIcon} alt="" className="ms-3" />
                    </div>
                  </MynuTooltip>
                }
              </span>
            </span>

            <span className="d-flex align-items-center justify-content-start">
              <span className="d-inline-block" >
                {resultsAvailable() && !RESULTS_DISABLED ?
                  <Link to="/results"
                    className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/results' && 'active-navbar-link'}`}
                  >
                    <span>{'Mis Resultados'}</span>
                  </Link>
                  :
                  <MynuTooltip
                    description={!resultsAvailable()
                      ? messages.NEED_RESULTS_MSG
                      : messages.OUT_OF_SERVICE_MSG
                    }
                    position='bottom'
                  >
                    <div className='nav-link mx-4 px-0 navbar-links'>
                      <span className='disabled-link'>Mis Resultados</span>
                      <img src={LockIcon} alt="" className="ms-3" />
                    </div>
                  </MynuTooltip>
                }
              </span>
            </span>

            <span className="d-flex align-items-center justify-content-start">
              <span className="d-inline-block" >
                <Link to="/questionary"
                  className={`nav-link mx-4 px-0 navbar-links ${location.pathname === '/questionary' && 'active-navbar-link'} ${!isSubscriptionBanner && 'disabled-link'}`}
                >
                  <span>Cuestionario</span>
                </Link>
              </span>
            </span>
          </Nav>
          <Nav className="d-flex align-items-center d-none d-lg-flex">
            <img src={MynuLogo} className="mx-2" alt="" width={32} height={32} />
            <NavDropdown
              className="navbar-dropdown"
              id="collapsible-nav-dropdown-user"
              title={`${currentUser?.givenName} ${currentUser?.familyName}`}
            >
              <NavDropdown.Item className="py-1 navbar-dropdown-item active">
                <Link to="/settings" className={`navbar-links`} id={"profile"}>
                  Mi Perfil
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={signOff} className="py-1 navbar-dropdown-item active">
                Cerrar sesión
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <hr />
          <Nav.Link className="mx-4 px-0 pb-3 navbar-links d-flex d-lg-none">
            <Link to="/settings" >
              Mi Perfil
            </Link>
          </Nav.Link>
          <Nav.Link onClick={signOff} className="mx-4 px-0 pb-3 navbar-links d-flex d-lg-none">
            Cerrar sesión
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppNavbar;

import { User } from "../models";
import { getDiet } from "../services/diet";
import { getQuestionnaire, getQuestionnaireOptions } from "../services/questionary/questionary-service";
import { getResults } from "../services/results/results";
import { getCurrentUser } from "../services/user/user";
import { isEmptyOrUndefined } from "../utils";
import { days } from "../utils/constants";
import { dietSummaryHelper, resultsHelper } from "../utils/resultsHelper";
import { AppState } from "./interfaces";

export const loadUserData = async (store: AppState) => {
  const { userData, setUserData, setIsLoadingUserData } = store;
  const userToken = localStorage.getItem('userToken');
  if (!userData && !!userToken) {
    setIsLoadingUserData(true)
    await getCurrentUser(userToken)
      .then((data) => setUserData(data))
      .catch((error) => {
        console.error('error: ', error?.message);
        setUserData(null);
      })
      .finally(() => setIsLoadingUserData(false));
  }
}

export const loadUserInfo = async (store: AppState, currentUser: User) => {
  const userToken = localStorage.getItem('userToken');
  if (currentUser?.id && userToken) {
    // results
    loadResults(store, currentUser, userToken);

    // questionnaire
    loadQuestionnaire(store, currentUser, userToken);
    loadQuestionnaireOptions(store, currentUser, userToken);

    // diet
    loadDiet(store, currentUser, userToken);
  }
}

export const setMealRestrictions = (store: AppState) => {
  const {
    resultsData,
    questionnaireData,
    questionnaireOptions,
    setDietMealRestrictions
  } = store;

  if (!isEmptyOrUndefined(resultsData, 'obj') && resultsData?.results && resultsData?.results.length > 0
    && !isEmptyOrUndefined(questionnaireData, 'obj')
    && !isEmptyOrUndefined(questionnaireOptions, 'obj')
  ) {
    let dietSummary = dietSummaryHelper(resultsData?.results, questionnaireData, questionnaireOptions);
    setDietMealRestrictions(dietSummary);
  }
}

export const loadDiet = async (store: AppState, currentUser: User | null, userToken: string | null) => {
  const {
    setIsLoadingDiet,
    setDietInformation,
    setDietStatus,
    setDietCreationFailed,
  } = store;
  if (currentUser?.resultsDate &&
    userToken) {
    setIsLoadingDiet(true);
    getDiet(days, userToken)
      .then((data) => {
        const { meals, status } = data;
        setDietInformation(meals);
        setDietStatus(status);
        setIsLoadingDiet(status === "LOADING");
        setDietCreationFailed(status === "FAILED");
      })
      .catch(error => {
        console.error('error: ', error?.message);
      })
  }
}

export const loadResults = async (store: AppState, currentUser: User, userToken: string | null) => {
  const {
    setIsLoadingResults,
    setResultsData,
    setResultStatus,
    setResultMealsRestrictions,
  } = store;

  if (userToken && currentUser?.id) {
    setIsLoadingResults(true);
    getResults(currentUser?.id, userToken)
      .then((data) => {
        const interpretedResults = data?.interpretedResults;
        setResultsData(interpretedResults);
        setResultStatus(data?.status || '');
        if (!isEmptyOrUndefined(interpretedResults, 'obj')) {
          setResultStatus(data?.status || '');
          let filteredMeals = resultsHelper(interpretedResults?.results);
          setResultMealsRestrictions(filteredMeals);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoadingResults(false));
  }
}

export const loadQuestionnaire = async (store: AppState, currentUser: User, userToken: string | null) => {
  const {
    setIsLoadingQuestionnaire,
    setQuestionnaireData,
  } = store;

  if (userToken && currentUser?.id) {
    setIsLoadingQuestionnaire(true);
    getQuestionnaire(userToken)
      .then(data => setQuestionnaireData(data))
      .catch((error) => console.log(error))
      .finally(() => setIsLoadingQuestionnaire(false));
  }
}


export const loadQuestionnaireOptions = async (store: AppState, currentUser: User, userToken: string | null) => {
  const {
    setIsLoadingQuestionnaireOptions,
    setQuestionnaireOptions,
  } = store;
  if (userToken && currentUser?.id) {
    setIsLoadingQuestionnaireOptions(true);
    getQuestionnaireOptions(userToken)
      .then(data => setQuestionnaireOptions(data))
      .catch((error) => console.log(error))
      .finally(() => setIsLoadingQuestionnaireOptions(false));
  }
}

export enum messages {
  OUT_OF_SERVICE_MSG = 'Por el momento fuera de servicio',

  NUMY_DISABLED_COMPLETE_QUESTIONNAIRE_MSG = 'Completa el cuestionario para chatear con Numy',
  NUMY_DISABLED_NEED_RESULTS_MSG = 'Necesitas tus resultados genéticos para chatear con Numy',
  
  DIETS_DISABLED_NEED_RESULTS_MSG = 'Necesitas tus resultados genéticos para ver tu dieta',
  DIETS_DISABLED_COMPLETE_QUESTIONNAIRE_MSG = 'Completa el cuestionario para ver tu dieta',
  
  NEED_RESULTS_MSG = 'Necesitas tus resultados genéticos para ver esta sección'
}

export const RESULTS_DISABLED = false;
export const NUMY_DISABLED = false;
export const DIETS_DISABLED = false;
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// react-bootstrap components
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/esm/Spinner";

//my components
import BuyKitLayout from "./components/BuyKitLayout";
import ShoppingCart from "./components/ShoppingCart";
import StepperComponent from "./components/StepperComponent";

// stepper steps
import KitStep from "./components/steps/KitStep";
import InfoStep from "./components/steps/InfoStep";
import DeliveryInfoForm from "./components/steps/DeliveryStep";
import ConfirmationStep from "./components/steps/ConfirmationStep";

import { submitTransbank } from "../../services/payment/submitTransbank";
import { anonymousOneTimeReveniuCheckout } from "../../services/subscription";
import { trackEvent } from "../../pixel";

//styles
import './BuyKitPage.scss';
import { KIT_PRICE_CLP, kitData } from "./constants";

export type BuyKitFormData = {
  province: string;
  kitQuantity: number;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  region: string;
  acceptedConditions: boolean;
}

export type BuyKitFormProps = {
  formData: BuyKitFormData;
  setFormData: (data: any) => void;

  kitData: {
    kitName: string;
    kitDescription: string;
    kitImage: string;
  };

  attributeValidations: {
    kitQuantity: boolean;
    acceptedConditions: boolean;
    phoneNumber: boolean;
    email: boolean;
    emailConfirmation: boolean;
    firstName: boolean;
    lastName: boolean;
    address: boolean;
    province: boolean;
  };

  setAttributeValidations: (data: any) => void;
}

const BuyKitPage = () => {

  const navigate = useNavigate();
  const [background, setBackground] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const handleSetToggleBackground = () => {
    setBackground(!background);
  }

  const [formData, setFormData] = useState<BuyKitFormData>({
    kitQuantity: 0,
    phoneNumber: '',
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: 'Santiago',
    region: 'RM',
    acceptedConditions: false,
    province: '',
  });

  const [attributeValidations, setAttributeValidations] = useState({
    kitQuantity: false,
    acceptedConditions: false,
    phoneNumber: false,
    email: false,
    emailConfirmation: false,
    firstName: false,
    lastName: false,
    address: false,
    province: false,
  });

  const [step, setStep] = useState(0);

  async function submitOrderData(formData: BuyKitFormData) {
    trackEvent('track', 'InitiateCheckout', {
      content_category: 'kit',
      content_type: 'kit_purchase',
      content_id: 'start_purchase',
      currency: 'CLP',
      value: formData.kitQuantity * KIT_PRICE_CLP,
      num_items: formData.kitQuantity,
    });
    const checkoutRes = await anonymousOneTimeReveniuCheckout({ ...formData, address: `${formData.address}, ${formData.address2}` });
    submitTransbank(checkoutRes.completion_url, checkoutRes.security_token)
  }

  function showAlert(variant: string, text: string) {
    console.log(variant, text);
  }

  async function changeStep(variation: 'forwards' | 'backwards') {
    if (variation === 'backwards' && step === 0) {
      navigate('/');
      return;
    }

    if (step === 3 && variation === 'forwards') {
      setLoadingSubmit(true);
      await submitOrderData(formData);
      setLoadingSubmit(false);
      return;
    }

    else if (step === 0 && variation === 'forwards'){
      trackEvent('track', 'AddToCart', {
        content_ids: ['kit'],
        content_category: 'add_kit_to_cart',
        content_name: 'Kit Genetico',
        currency: 'CLP',
        value: KIT_PRICE_CLP * formData.kitQuantity,
      })
    }

    const newStep = variation === 'forwards' ? step + 1 : step - 1;

    if (variation === 'forwards') {
      const form = document.getElementById('buy-kit-form') as HTMLFormElement;
      const newValidation = validateAttributes();

      //Valida si algun atributo del formulario es inválido
      if (Object.values(newValidation).some((validation) => validation === true)) {
        showAlert('warning', 'Debes completar todos tus datos')
      } else {
        if (form.checkValidity() === true) {
          setStep(newStep);
        }
      };
    } else {
      setStep(newStep);
    }

  }

  const validateAttributes = () => {
    const newValidation = {
      ...attributeValidations
    };

    switch (step) {
      case 0:
        newValidation.kitQuantity = Boolean(!formData.kitQuantity);
        newValidation.acceptedConditions = Boolean(!formData.acceptedConditions);
        break;
      case 1:
        newValidation.firstName = Boolean(!formData.firstName);
        newValidation.lastName = Boolean(!formData.lastName);
        newValidation.phoneNumber = Boolean(!formData.phoneNumber);
        break;
      case 2:
        newValidation.address = Boolean(!formData.address);
        newValidation.province = Boolean(!formData.province);
        break;
      default:
        break;
    }
    setAttributeValidations(newValidation);
    return newValidation;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    changeStep('forwards');
  }

  useEffect(() => {
    trackEvent('track', 'ViewContent', {
      content_name: 'BuyKitPage',
      content_category: 'Page',
    });
  }, [])

  return (
    <BuyKitLayout>
      <Card className="desktop-hidden">
        <Card.Header>
          <div className="header-text">Compra tu kit genético aquí!</div>
        </Card.Header>
      </Card>

      <Form id="buy-kit-form" noValidate onSubmit={handleSubmit} className="d-flex flex-column">
        <StepperComponent
          activeStep={step}
          setActiveStep={setStep}
          stepHeaders={[
            '¿Cuántos kits quieres comprar?',
            'Información de contacto',
            'Información de envío',
            'Por favor, confirma tus datos'
          ]}
          steps={[
            <KitStep
              formData={formData}
              setFormData={setFormData}
              kitData={kitData}
              attributeValidations={attributeValidations}
              setAttributeValidations={setAttributeValidations}
            />,
            <InfoStep
              formData={formData}
              setFormData={setFormData}
              kitData={kitData}
              attributeValidations={attributeValidations}
              setAttributeValidations={setAttributeValidations}
            />,
            <DeliveryInfoForm
              formData={formData}
              setFormData={setFormData}
              kitData={kitData}
              attributeValidations={attributeValidations}
              setAttributeValidations={setAttributeValidations}
            />,
            <ConfirmationStep
              formData={formData}
              submitOrderData={submitOrderData}
              changeStep={changeStep}
            />
          ]}
        >
          <div className="bottom-container">
            <ShoppingCart
              kitQuantity={formData.kitQuantity}
              kitData={kitData}
              handleSetToggleBackground={handleSetToggleBackground}
              step={step}
            />
            <Card className="buttons-container">
              <div className="terms py-2">
                Al comprar el kit estás aceptando nuestros <a href="https://mynu.ai/terminos" target="_blank" rel="noreferrer">
                  Términos y Condiciones</a>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <Button variant="danger" onClick={() => { changeStep('backwards') }}> Atras </Button>
                {loadingSubmit ?
                  <div className="d-flex justify-content-center align-items-center w-50">
                    <Spinner animation="border" variant="primary" />
                  </div>
                  :
                  <Button className="primary" onClick={() => { changeStep('forwards') }}> {step === 3 ? "Continuar al pago!" : "Siguiente!"} </Button>
                }
              </div>
            </Card>
          </div>
        </StepperComponent>

      </Form>

      {background ? <div className="background-blur"></div> : null}
      
      {/* space fill when display vertical size is too small */}
      <div className='space-fill' />

    </BuyKitLayout >)
}

export default BuyKitPage;

import '../../styles/App.scss';
import './WelcomeSection.scss';
import { Card, Col, Row } from "react-bootstrap";
import { Subscription } from '../../models';
import SubscriptionBadge from '../../components/SubscriptionBadge/SubscriptionBadge'

type WelcomeSectionProps = {
    userName?: string | null;
    userGender?: string | null;
    subscription?: Subscription | null;
}

function WelcomeSection({ userName, userGender, subscription }: WelcomeSectionProps) {
   return (
      <Card className="p-4 mb-4 home-card">
        <Row>
            <Col sm="10">
                <h1 className="home-title">{`¡${userName}, ${!userGender ? 'bienvenido/bienvenida' : userGender === 'Femenino' ? 'bienvenida' : 'bienvenido'} a Mynu!`}</h1>
            </Col>
            <SubscriptionBadge subscription={subscription} />
        </Row>
        <Row>
            <p className="home-text mt-3">Tu camino hacia una vida más saludable comienza aquí. Personalizamos planes de nutrición para ayudarte a alcanzar tus objetivos de bienestar.</p>
        </Row>
      </Card>
    )
}

export default WelcomeSection;


import tbsp from '../../../assets/icons/diet-portion-icons/tbsp.svg';
import ladle from '../../../assets/icons/diet-portion-icons/ladle_2.svg';
import cup from '../../../assets/icons/diet-portion-icons/cup.svg';
import closedHand from '../../../assets/icons/diet-portion-icons/closed_hand.svg';
import plate from '../../../assets/icons/diet-portion-icons/plate.svg';
import palm from '../../../assets/icons/diet-portion-icons/palm.svg';
import pizza from '../../../assets/icons/diet-portion-icons/pizza.svg';
import pancakes from '../../../assets/icons/diet-portion-icons/pancakes.svg';
import open_hand from '../../../assets/icons/diet-portion-icons/open_hand.svg';
import fingers from '../../../assets/icons/diet-portion-icons/2_fingers.svg';
import lasagna from '../../../assets/icons/diet-portion-icons/lasagna.svg';

export default function DietPortionsComponent() {
  return (
    <div className="d-flex flex-wrap justify-content-center align-items-center m-1">
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        <OneColumnPortion name={'Cuchara'} desc={'de 15ml'} imgSrc={tbsp} />
        <OneColumnPortion name={'Cucharadita'} desc={'de 5ml'} imgSrc={tbsp} />
        <OneColumnPortion name={'Cucharón'} desc={'de 240ml'} imgSrc={ladle} />
        <OneColumnPortion name={'Taza'} desc={'de 200ml'} imgSrc={cup} />
        <OneColumnPortion name={'Puño'} desc={'Cerrado'} imgSrc={closedHand} />
        <OneColumnPortion name={'Puñado'} desc={'Palma abierta'} imgSrc={open_hand} />

      </div>
      <div className="d-flex flex-wrap justify-content-center align-items-center m-1">
        <TwoColumnPortion name={'Trozo de carne'} desc={'Tamaño de la palma de tu mano sin los dedos (100 gr)'} imgSrc={palm} />
        <TwoColumnPortion name={'Trozo de queso Fresco'} desc={'Trozo de 2 dedos de grosor (30 gr)'} imgSrc={fingers} />
        <TwoColumnPortion name={'Trozo de tortilla'} desc={'1/4 de tortilla del tamaño de un plato principal'} imgSrc={plate} />
        <TwoColumnPortion name={'Trozo de lasaña'} desc={'Trozo cuadrado del tamaño de 3/4 de la mano (300 gr)'} imgSrc={lasagna} />
        <TwoColumnPortion name={'Trozo de pizza'} desc={'1/4 de una pizza personal'} imgSrc={pizza} />
        <TwoColumnPortion name={'Croqueta'} desc={'Tamaño de la palma de tu mano 8 cm de diámetro'} imgSrc={palm} />
        <TwoColumnPortion name={'Panqueques y crepes'} desc={'Tamaño aproximado de 10 a 13 cm de diámetro'} imgSrc={pancakes} />
      </div>
    </div>
  )
}

const OneColumnPortion = (props: { name: string, desc: string, imgSrc: string }) => {
  const { name, imgSrc, desc } = props;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center m-1 bg-eq-list-item">
      <div className='w-40'>
        <img src={imgSrc} alt={desc} width={'60rem'} />
      </div>
      <div className='d-flex flex-column align-items-center'>
        <b className='mx-3'>
          {name}
        </b>
        <div className='mx-3'>
          {desc}
        </div>
      </div>
    </div>
  )
}

const TwoColumnPortion = (props: { name: string, desc: string, imgSrc: string }) => {
  const { name, imgSrc, desc } = props;

  return (
    <div className="d-flex flex-row align-items-center m-2 bg-eq-list-item-2">
      <div className='w-40'>
        <img src={imgSrc} alt={desc} width={'60rem'} className='m-2' />
      </div>
      <div className='d-flex flex-column align-items-start' style={{}}>
        <b className='mx-3'>
          {name}
        </b>
        <div className='mx-3'>
          {desc}
        </div>
      </div>
    </div>
  )
}
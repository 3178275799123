import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Signup.scss';
import InitialLayout from '../../components/InitialLayout/InitialLayout';
import MynuBlueLogo from '../../assets/images/mynu-blue-logo.png';
import SignupForm from './SignupForm';
import BackButton from '../../components/BackButton/BackButtton';
import { registerNewUser } from '../../services/auth/auth-service';
import { NewUser } from '../../models';
import Notification from '../../components/Notification/Notification';

function Signup() {
  const navigate = useNavigate();
  const [hasAlert, setHasAlert] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    variant: '',
    text: ''
  })
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const submitUserData = async (userData: NewUser) => {
    try {
      await registerNewUser(userData);
      if (urlParams.get('testId')) {
        navigate(`/code-confirmation?testId=${urlParams.get('testId')}`);
      } else {
        navigate('/code-confirmation');
      }
    } catch (error: any) {
      showAlert('danger', error.message)
    }
  }

  const showAlert = (variant: string, text: string) => {
    setHasAlert(true);
    setAlertDetails({
      variant,
      text
    });
  }

  return (
    <InitialLayout>
      <div className="py-3">
        <BackButton text="Volver al Home" />
      </div>
      <Notification
        show={hasAlert}
        title={alertDetails && alertDetails.variant === 'danger' ? 'Error' : 'Alerta'}
        variant={alertDetails.variant}
        text={alertDetails.text}
        onClose={() => setHasAlert(false)}
      />
      <div className="form-container pt-2">
        <div className="text-center d-lg-none pb-3">
          <img src={MynuBlueLogo} className="mobile-logo" alt="" width={123} />
        </div>
        <h1 className="form-title text-center mb-3">¡Bienvenido/Bienvenida!</h1>
        <SignupForm submitUserData={submitUserData} showAlert={showAlert} />
      </div>
      <div className="text-center redirect-note">
        <span className='font-avenir-bold'>¿Ya tienes cuenta? </span>
        <Link to="../login">Ingresa aquí</Link>
      </div>
    </InitialLayout>
  );
}

export default Signup;

import { 
  // Button,
  Card,
  Col,
  Container,
  Row
} from "react-bootstrap";
import SubscriptionImage from '../../assets/images/kit-image.png';
import SubscriptionImageMobile from '../../assets/images/kit-image-mobile.png';
import SubscriptionIcon from '../../assets/icons/kit-icon.svg';

type KitAdnSectionProps = {
  showKitAdnCard: boolean;
  onClick?: () => void;
}

function KitAdnSection({ showKitAdnCard, onClick }: KitAdnSectionProps) {
  if(showKitAdnCard){
   return (
      <Card className="mb-4 home-card">
        <Container>
          <Row>
            <Col lg="auto" xs={12} className="px-0">
              <img src={SubscriptionImage} alt="" className="home-section-image banner-kit d-none d-lg-block"/>
              <img src={SubscriptionImageMobile} alt="" className="home-section-image d-flex d-lg-none"/>
            </Col>
            <Col lg={5} xs={12} className="pt-3 pb-lg-0">
              <h2 className="home-title subtitle">
                <img src={SubscriptionIcon} alt="" className="me-3"/>
                ¿Por qué adquirir mi Kit de ADN?
              </h2>
              <p className="home-text mt-3 text-center text-lg-start">
                Nutrición personalizada con tu ADN:
              </p>
              <ul>
                <li>Adaptamos tu dieta a tu genética.</li>
                <li>Pierde peso y previene enfermedades.</li>
                <li>Planifica dietas especiales con precisión.</li>
                <li>Decisión informada desde el principio.</li>
                <li><a href='https://mynu.ai/ciencia-conciencia/' target="_blank" rel='noreferrer'>Más Información</a></li>
              </ul>

            </Col>
            <Col className="home-button-container">
              {/* <Button variant="primary" className="home-button" onClick={onClick} >Comprar Test</Button> */}
            </Col>
          </Row>
        </Container>
      </Card>
   )
  }
  return <></>
}

export default KitAdnSection;

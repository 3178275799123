import React, { FC, useState, useCallback, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { QuestionnaireCard } from './QuestionnaireCard';
import MynuTooltip from '../../../components/Tooltip/Tooltip';
import SearchComponent from '../../../components/SearchComponent/SearchComponent';

import {CheckBoxOption} from './CheckBoxComponent';

const { Check, Label, Text } = Form;

export type SelectOption = {
  id: string;
  value: number;
}

type PickerListProps = {
  name: string;
  question: string;
  required: boolean;
  description?: string;
  options: CheckBoxOption[];
  initiallySelectedIds: string[];
  updateSelectedIds: (selectedIds: string[]) => void;
  isValid: (selectedIds: string[]) => boolean
  showInvalid?: boolean;
  selectorOptions: SelectOption[];
  OptionAdornmentComponent?: React.ComponentType<any>;
  renderAdornmentName?: string;
  renderAdornmentValues?: {id: string, label: string}[];
  renderAdornmentKey?: string;
  updateAdornmentOptions?: (arg0: string, arg1: number) => void;
  adornmentText?: string;
  adornmentTextOffset?: number;
}

const DescriptionComponent: FC<{ text: string }> = ({ text }) => {
  return (
    <p><Text style={{ 'fontSize': '12px' }}>{text}</Text></p>
  )
}

const PickerList: FC<PickerListProps> = (props: PickerListProps) => {

  const {
    name,
    question,
    description,
    required,
    options,
    initiallySelectedIds,
    updateSelectedIds,
    isValid,
    showInvalid,
    selectorOptions,
    updateAdornmentOptions,
    OptionAdornmentComponent,
    renderAdornmentName,
    renderAdornmentValues,
    adornmentText,
    adornmentTextOffset		
  } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<CheckBoxOption[]>(options);

  useEffect(() => {
    setSelectedIds(initiallySelectedIds);
  }, [initiallySelectedIds]);

  const triggerUpdate = useCallback((newIds: string[]) => {
    setSelectedIds(newIds);
    updateSelectedIds(newIds);
  }, [updateSelectedIds]);
  
  const handleSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>, switchedId: string) => {
    const checked = event.target.checked;

    const element = options.find(({ id }) => id === switchedId);
    if (!element) throw new Error('No such option with id: ' + switchedId);

    const newIds = !checked ? selectedIds.filter(optId => optId !== element.id) : [...selectedIds, element.id];
    if (!checked) {
      updateAdornmentOptions && updateAdornmentOptions(switchedId, 0);
    }

    triggerUpdate(newIds);
  }, [options, triggerUpdate, selectedIds, updateAdornmentOptions]);


  const handleClick = (switchedId: string) => {
    const element = options.find(({ id }) => id === switchedId);

    if (!element) throw new Error('No such option with id: ' + switchedId);

    const newIds = selectedIds.includes(element.id) ? selectedIds.filter(optId => optId !== element.id)
      : [...selectedIds, element.id];

    if (selectedIds.includes(element.id)) {
      updateAdornmentOptions && updateAdornmentOptions(switchedId, 0);
    }
    triggerUpdate(newIds);
  };

  return (
    <QuestionnaireCard>
      <Container>

      <Row>
        <Col className="mb-1">
          <Label className="signup-form-label">{question}</Label>
          {description ? <DescriptionComponent text={description} /> : null}
        </Col>
      </Row>
      <Row>
        <Col sm={8} id={'search-container'} style={{ minWidth: 300, maxWidth: '100%' }}>
          <SearchComponent
            placeholder='Buscar Deporte...'
            searchText={searchText}
            setSearchText= {setSearchText}
            options= {options}
            filteredOptions= {filteredOptions}
            setOptions= {setFilteredOptions}
            required={required && (showInvalid ? !isValid(selectedIds) : false)}
            useLabels={true}
            useNames={true}
            name='id'
            RenderItem={Check}
            renderItemType="checkbox"
            id={'id'}
            checkResultsAvailable={true}
            isInvalid={showInvalid ? !isValid(selectedIds) : false}
            isValid={showInvalid ? isValid(selectedIds) : false}
            onChange={handleSwitch}
            onClick={handleClick}
            selectedIds={selectedIds}
          />
        </Col>
        {
          selectedIds.length > 0 &&
          <Col sm={2} className='d-flex align-self-center my-1 d-none d-sm-block'>
            <Button className="questionnaire-step-button" variant="outline-primary"
              onClick={() => {
                setSelectedIds([]);
                updateSelectedIds([]);
              }}
            >
              Deseleccionar
            </Button>
          </Col>
        }
      </Row>
      {
        selectedIds.length > 0 &&
        <Row style={{display: 'flex', flexDirection: 'row-reverse'}}>
          <Col xs={12} sm={6} md={6} lg={3}
            style={{textAlign: 'center', marginLeft: `${adornmentTextOffset ?? 0}px`}}
          >
            {adornmentText}
          </Col>
        </Row>
      }
      <Row
        style={{
          'maxHeight': '16em',
          'overflowY': 'auto',
          'overflowX': 'hidden',
        }}
      >
        {options.map(({ id, label, tooltip, icon }: CheckBoxOption) =>
          selectedIds.includes(id) &&
          <Row key={label + "-" + id} >
            <Col xs={8} md={8} lg={10} className="align-self-center" >
              <Check
                required={required && (showInvalid ? !isValid(selectedIds) : false)}
                label={
                  tooltip ? 
                  <MynuTooltip description={tooltip}	>
                    <label className="fw-normal">{label}</label>
                  </MynuTooltip> 
                  : 
                  <span> 
                    {icon ? <><img src={icon} alt="" width={20} /> </> : ''}
                    {label}
                  </span>
                }
                name={id}
                type="checkbox"
                id={id}
                checked={selectedIds.includes(id)}
                isInvalid={showInvalid ? !isValid(selectedIds) : false}
                isValid={showInvalid ? isValid(selectedIds) : false}
                onChange={(event) => handleSwitch(event, id)}
              />
            </Col>
            <Col xs={4} md={2} lg={2} className="align-self-center">
              { OptionAdornmentComponent &&
                <OptionAdornmentComponent
                  name={name + id + renderAdornmentName}
                  options={renderAdornmentValues}
                  required={true}
                  initialValue={selectorOptions.find(element => element.id === id)?.value?.toString()}
                  updateValue={(selectedId: number) =>{updateAdornmentOptions && updateAdornmentOptions(id, selectedId)}}
                  isValid={(selectedId: string) => selectedId.length > 0}
                  showInvalid={showInvalid}
                  maxWidth={'6rem'}
                />
              }
            </Col>
          </Row>
        )}
      </Row>
      </Container>

    </QuestionnaireCard>
  )
}

export default PickerList;
import { Button } from "react-bootstrap";
import { Instagram, Linkedin, Tiktok } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

export function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer p-4">
      <div className="flex-container align-items-start pt-3">

        <div className='point' style={{textWrap: "nowrap"}} onClick={()=> {navigate('/buy-kit')}}>
          <div className="footer-container link mx-3 subsub-text">{"Pide el kit aqui ;)"}</div>
        </div>
        <div className="footer-container d-flex flex-column align-items-start mx-3">
          <Button
            onClick={() => navigate('/login')}
            variant="outline-warning"
            className="link footer-button button-warning capitalized px-4 py-2 spaced-3">
            Login
          </Button>
          <Button
            onClick={() => navigate('/signup')}
            className="footer-button capitalized px-4 py-2 spaced-3"
          >
            Registro
          </Button>
        </div>

        <div className="footer-container d-flex flex-column align-items-start mx-3">
          <a href={"https://mynu.ai"} target="_blank" rel="noreferrer">
            <div className="d-flex flex-row align-items-center mb-4" >
              <Tiktok className="footer-icon mx-2" />
              <div className="footer-link mx-2">TikTok</div>
            </div>
          </a>
            <a href={"https://www.instagram.com/mynu.ai/"} target="_blank" rel="noreferrer">
              <div className="d-flex flex-row align-items-center mb-4">
                <Instagram className="footer-icon mx-2" />
                <div className="footer-link mx-2">Instagram</div>
              </div>
            </a>
          <a href={"https://www.linkedin.com/company/mynu-ai/"} target="_blank" rel="noreferrer">
            <div className="d-flex flex-row align-items-center mb-4">
              <Linkedin className="footer-icon mx-2" />
              <div className="footer-link mx-2">Linkedin</div>
            </div>
          </a>
        </div>

        <div className="footer-container d-flex flex-column align-items-start mx-3">
          <a href="https://mynu.ai/blogs/" target="_blank" rel="noreferrer">
            <div className="footer-text mx-2 m-3">Blogs</div>
          </a>
          <a href="https://mynu.ai/a-preguntas-frecuentes/" target="_blank" rel="noreferrer">
            <div className="footer-text mx-2 m-3">Preguntas frecuentes</div>
          </a>
          <a href="https://mynu.ai/terminos/" target="_blank" rel="noreferrer">
            <div className="footer-text small mx-2 m-2">Términos y condiciones</div>
          </a>
          <a href="https://wa.me/56923846918?text=Tengo%20una%20duda%20sobre%20mynu" target="_blank" rel="noreferrer">
            <div className="footer-text small mx-2 m-2">Hablemos por whatsapp</div>
          </a>
          <a href="mailto:contacto@mynu.ai">
            <div className="footer-text small mx-2 m-2">contacto@mynu.ai</div>
          </a>
        </div>
      </div>
      <div className="content-center">
        <div className="h-line" />
      </div>

      <div className="flex-r justify-content-between bottom-text">
        <div className="footer-text extra-small d-flex">Thanks for visiting.</div>
        <div className="footer-text extra-small d-flex">© 2024 mynu.ai. All rights reserved</div>
      </div>

    </div>
  )
}
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { diet_summary_texts } from './constants';
import { ArrowDownCircleFill } from 'react-bootstrap-icons';
import { useRef } from 'react';


function DietSummaryItem({ values, classProp }: { values: any, classProp: string }) {
  const { name, foods } = values;

  const scrollableRef = useRef<HTMLDivElement>(null);

  let maxAvoid: any[] = []
  if (classProp === 'avoid') {
    maxAvoid = foods.filter((meal: any) => Object.keys(meal).includes('id') && meal.cat === 'allergies' ? meal : null)
  }

  const scrollHandler = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollBy({
        top: scrollableRef.current.scrollHeight,
        behavior: 'smooth', // This makes the scroll smooth
      });
    }
  };

  return (
    <div className="summary-card mb-4 pb-2" key={'card-recomendation-' + classProp}>
      <div className='summary-item'>
        <div className={"d-flex justfy-content-center title " + classProp} >
          <span className={"title-box p-2 m-1"}>
            {name}
          </span>
        </div>
        <div className='d-flex flex-column justify-content-between align-items-center'>
          <div className='fill-text'>
            <Markdown remarkPlugins={[remarkGfm]}>{diet_summary_texts[classProp]?.description}</Markdown>
          </div>
          <div className='d-flex justify-content-center' style={{position: 'relative'}}>
            <div className='item-container' ref={scrollableRef}>
              {Object.keys(diet_summary_texts[classProp]).includes('subsubtitle')
                && maxAvoid.length > 0 &&
                <div className='sub-text'>
                  {diet_summary_texts[classProp]?.subsubtitle}
                </div>
              }
              {maxAvoid.length > 0 && <>
                <div className='wrapper'>
                  {maxAvoid.map((meal: any, index) => (<div className={classProp + '-text'} key={meal + index}>{meal.name}</div>))}
                </div>
              </>}
              {Object.keys(diet_summary_texts[classProp]).includes('subtitle') &&
                <div className='sub-text'>
                  {diet_summary_texts[classProp]?.subtitle}
                </div>
              }
              <div className='wrapper'>
                {foods.map((meal: any, index: number) => {
                  if (maxAvoid.indexOf(meal) === -1) {
                    return (<div className={classProp + '-text'} key={meal + index}>{meal.name}</div>)
                  } return null
                })}
              </div>
            </div>
            {foods.length > 5 &&
              <div className='scroll-arrow'>
                <ArrowDownCircleFill size={30} className='color-gray' onClick={scrollHandler} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DietSummaryItem;
import { FC, useState, useEffect, useCallback } from 'react';
import {Form} from 'react-bootstrap';
import { QuestionnaireCard } from './QuestionnaireCard';

const { Control, Group, Label, Text } = Form;

type TextComponentProps = {
  name: string;
  question: string;
  placeholder?: string;
  required: boolean;
  initialText?: string;
  updateText: (value: string) => void;
  isValid?: (value: string) => boolean;
  caveat?: string;
};

const TextComponent: FC<TextComponentProps> = (props: TextComponentProps) => {
  const {
    name,
    question,
    placeholder,
    required,
    initialText,
    updateText,
    isValid,
    caveat
  } = props;
  const [text, setText] = useState<string>('');
  
  useEffect(() => {
    if (initialText) {
      setText(initialText)
    }
  }, [initialText]);
  
  const checkValidInput = useCallback((): boolean => {
    if (isValid) return isValid(text);
    return true;
  }, [isValid, text]);
  
  const triggerTextUpdate = useCallback( (newText:string) => {
    setText(newText);
    updateText(newText);
  }, [updateText]);
  
  return (<QuestionnaireCard>
    <Group className="p-2" controlId={name} key={name} >
      <Label className="form-label">{question}</Label>
      <Control 
        required={required}
        name={name}
        type='text'
        placeholder={placeholder}
        value={text}
        onChange={(event) => {
          triggerTextUpdate(event.target.value);
        }}
        isInvalid={!checkValidInput()}
      />
      {caveat && <Text className="text-muted form-assistive-text">
        * {caveat}
      </Text>}
    </Group>
  </QuestionnaireCard>);
};

export default TextComponent
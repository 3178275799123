import React from 'react';
import { Spinner } from 'react-bootstrap';

import './BackdropComponent.scss';

type BackdropComponentProps = {
  visible: boolean;
  displayText?: string;
};

const BackdropComponent: React.FC<BackdropComponentProps> = (props: BackdropComponentProps) => {
  const { visible, displayText } = props;
  return ( 
    visible ?
      <div className="backdrop">
        <div className='font-avenir-bold display-text'>{displayText}</div>
        <Spinner animation="border" role="status" className='align-self-center'/>
      </div>
    : null
  );
}

export default BackdropComponent;
import { Categories } from "../pages/results/Categories";
import { validPredispositions } from "../pages/results/components/resultsCategories/RestultsConstants";
import { rmUndefinedOrNulls, rmDuplicates, compareStrings } from "./common";
import { summary_index } from "../pages/diets/components/SummaryItem/constants";
import { diet_patterns } from "../pages/diets/components/SummaryItem/diet_pattern_restrictions";
import { Category } from "../models";

export const resultsHelper = (resultsData: any) => {
  let allowed: string[] = []
  let reduced: string[] = []
  let avoided: string[] = []
  // food consistency categorization
  // checks all foods for each category->advice->food recomendation
  // and categorizes them in allowed, avoid and reduce
  // the use checkFoodConsistency function to check if the food
  // is consistent with the advice and categories
  let lactose_intollerant = false;
  if(resultsData?.find((cat: any) => cat.category_name === 'allergies') ){
    let predisposition = resultsData
      .find((cat: any) => cat.category_name === 'allergies')?.subcategories
      .find((subcat: any) => subcat.subcategory_name === 'allergies_lactose')?.predisposition;
      lactose_intollerant = predisposition === 'high' || predisposition === 'medium';
  }
  resultsData.forEach((userCategory: any) => {
    userCategory.subcategories.forEach((usrSbcat: any) => {
    let predisposition = usrSbcat.predisposition;
    Categories.forEach(category => {
      category.subcategories.forEach((subcategory: any) => {
        if(usrSbcat.subcategory_name === subcategory.subcategory_name && validPredispositions.includes(predisposition)){
          let advs = subcategory[predisposition]['advice'];
          if (!(typeof advs === 'string')){
            let advices: string[] = typeof advs === 'string' ? [advs] : advs;
            if(advices.includes('reduce') || advices.includes('avoid') || advices.some(a=>a.includes('prefer'))){
              advices.forEach((advice: string) => {
                let foodList = subcategory[advice];
                foodList.forEach((food: string) => {
                  let prfs = ['prefer', 'prefer1', 'prefer2', 'prefer3']
                  if(prfs.some(p=>advice.includes(p)) && !(reduced.includes(food) || avoided.includes(food))){
                    let food_l = food.toLocaleLowerCase();
                    if (lactose_intollerant && food_l.includes('lácteos con lactosa')){
                      return;
                    }
                    const newAllowed = Array.from(new Set([...allowed, food]));
                    allowed = newAllowed;
                  }
                  if(advice.includes('reduce') && !allowed.includes(food)){
                    const newReduced = Array.from(new Set([...reduced, food]));
                    reduced = newReduced;
                  }
                  if(advice.includes('avoid') && !allowed.includes(food)){
                    const newAvoided = Array.from(new Set([...avoided, food]));
                    avoided = newAvoided;
                  }
                })
              })
            }     
          }
        }
      })
      })
    })
  });

  return [allowed, reduced, avoided];
}


export const dietSummaryHelper = (resultsData: Category[], questionnaireData: any, questionnaireOptions: any) => {

  // i rather not talk about this, MS
  // kinda sorry for this
  let summary: any = {
    pref: [],
    red: [],
    avoid: []
  }
  
  let pre_avoid: any[] = [];
  const quest_data = questionnaireData?.questionnaire;
  let diet_restriction_id = quest_data.dietRestrictionTypeIds.length > 0 ? quest_data.dietRestrictionTypeIds[0] : '';
  diet_restriction_id = diet_restriction_id.replaceAll('-', '_');
  const diet_restrictions = diet_restriction_id.length > 0 ? diet_patterns[diet_restriction_id] : [];

  let condition_ids = [...quest_data.allergyOrIntoleranceIds, ...quest_data.medicalConditionIds];
  
  const quest_options = [...questionnaireOptions?.allergiesOrIntoleranceOptions, ...questionnaireOptions?.medicalConditionOptions];

  const parsed_conditions = quest_options.filter((a: any) => condition_ids.includes(a.id))
    .map((a: any) => ({name: a.label, reps: 1, risk: 'high', id: a.id}));
  pre_avoid = parsed_conditions.filter((a: any) => a.name !== 'Otros');

  let avoided = summary_index.filter((s: any) => {
    return pre_avoid.some((a: any) => a.id === s.questionnaire_id)
  });
  
  let lactose_intollerant = avoided.find((a: any) => a.questionnaire_id === 'lactose') ? true : false;

  avoided.forEach((food: any) => {
    let foodList = food.avoid.split(',').map((f: string) => f.trim());
    foodList.forEach((f: string) => {
      let fd = summary.avoid.find((food: any) => food.name === f);
      if(fd){
        fd.reps += 1;
      } else {
        summary.avoid.push({name: f, reps: 1, risk: 'high', cat: food.cat, id: food.questionnaire_id});
      }
    })
  })

  resultsData.forEach((userCategory: any) => {
    userCategory.subcategories.forEach((usrSbcat: any) => {
    let predisposition = usrSbcat.predisposition;
    Categories.forEach(category => {
      category.subcategories.forEach((subcategory: any) => {
        if(usrSbcat.subcategory_name === subcategory.subcategory_name && validPredispositions.includes(predisposition)){
          let advs = subcategory[predisposition]['advice'];
          if (!(typeof advs === 'string')){
            let advices: string[] = typeof advs === 'string' ? [advs] : advs;
            if(advices.includes('reduce') || advices.includes('avoid') || advices.some(a=>a.includes('pref'))){
              advices.forEach((advice: string) => {
                let foodList = subcategory[advice];
                foodList.forEach((food: string) => {
                  // diet pattern meal restrictions
                  if (diet_restrictions.length > 0 && !diet_restrictions.find((f: string) => {
                    try {
                      return compareStrings(f, food, 0.95);
                    } catch (e) {
                      return false;
                    }
                  })){
                    return;
                  }
                  advice = advice.includes('pref') ? 'pref' : advice.includes('red') ? 'red' : 'avoid';
                  let fd = summary[advice].find((f: any) => f && f.name === food && f.risk === predisposition && f.cat === category.id);
                  if(fd){
                    fd.reps += 1;
                  } else {
                    let newFood = {name: food, reps: 1, risk: predisposition, cat: category.id};
                    if(advice.includes('pref') 
                        && !(summary?.red?.find((f: any) => f.name === food) || summary?.avoid?.find((f: any) => f.name === food))){
                      if (lactose_intollerant && food.toLowerCase().includes('lácteos con lactosa')){
                        return;
                      }
                      summary = { ...summary,  [advice]: [...summary[advice], newFood ] }
                    } 
                    else if(advice.includes('red')
                        && !(summary?.prefer?.find((f: any) => f.name === food) || summary?.avoid?.find((f: any) => f.name === food))){
                      summary = { ...summary,  [advice]: [...summary[advice], newFood ] }

                    }
                    else if(advice.includes('avoid')
                        && !(summary?.red?.find((f: any) => f.name === food) || summary?.prefer?.find((f: any) => f.name === food))){
                      summary = { ...summary,  [advice]: [...summary[advice], newFood ] }
                    }
                  }
                })
              })
            }     
          }
        }
      })
      })
    })
  });

  const prioritizeFoods = (obj: any) => {
    return obj.map((food: any) => {
      if (food.name.trim() === '') return null;

      const {reps, risk, cat} = food;
      if(cat === 'allergies'){
        return food;
      } else if(risk === 'high' && cat !== 'wellness' ){
        return food
      } else if ((reps === 2) && ((cat === 'wellness' && risk === 'high') || (risk === 'medium'))){
        return food
      } else if (reps >= 3 && ((cat === 'wellness' && risk === 'medium') || (risk === 'low'))){
        return food
      }
      return null;
    })
  }

  summary.pref = {name: 'Prefiere', foods: rmDuplicates(rmUndefinedOrNulls(prioritizeFoods(summary.pref)), 'obj', 'name')};
  summary.red = {name: 'Reduce', foods: rmDuplicates(rmUndefinedOrNulls(prioritizeFoods(summary.red)), 'obj', 'name')};
  summary.avoid = {name: 'Evita', foods: rmDuplicates(rmUndefinedOrNulls(prioritizeFoods(summary.avoid)), 'obj', 'name')};

  return summary;
}
import {FC} from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './Tooltip.scss';

type MynuTooltipProps = {
  description: string;
  children : any;
  position?: 'bottom' | 'top' | 'left' | 'right';
}

const MynuTooltip: FC<MynuTooltipProps> = (props: MynuTooltipProps) => {
  const {
    description, 
    children,
    position
  } = props;
  
  return (
    <OverlayTrigger
      placement={position ?? 'top'}
      overlay={
        <Tooltip
          className="mynu-tooltip"
        >
          {description}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
} 

export default MynuTooltip;
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import QuestionaryImage from '../../assets/images/questionary-image.png';
import QuestionaryImageMobile from '../../assets/images/questionary-image-mobile.png';
import QuestionaryIcon from '../../assets/icons/questionary-icon.svg';

type QuestionarySectionProps = {
  progress?: number | null;
  subscriptionValid: boolean;
}

const COMPLETE_QUESTIONNAIRE_MSG : string = "¡Cuestionario completo!";

function QuestionarySection({ subscriptionValid, progress }: QuestionarySectionProps) {
  const navigate = useNavigate();  
  return (
      <Card className="mb-4 home-card">
        <Container>
          <Row>
            <Col lg="auto" xs={12} className="px-0">
              <img src={QuestionaryImage} alt="" className="home-section-image banner-questionnaire d-none d-lg-block" />
              <img src={QuestionaryImageMobile} alt="" className="home-section-image d-flex d-lg-none"/>
            </Col>
            <Col className="pt-3 pb-lg-0">
              <h2 className="home-title subtitle">
                <img src={QuestionaryIcon} alt="" className="me-3"/>
                { progress && progress > 0 ? COMPLETE_QUESTIONNAIRE_MSG : 'Cuestionario de perfilamiento' }
              </h2>
              <p className="home-text mt-3 text-center text-lg-start">
                {
                  progress && progress > 0 ?
                    'Las respuestas que obtendremos de las preguntas se tomarán en cuenta para identificar sus riesgos con mayor precisión.'
                  : 
                    'Completa tu perfil ahora para recibir recomendaciones personalizadas sobre tu nutrición que se ajusten a tus ' + 
                    'necesidades y objetivos. ¡Tu camino hacia el bienestar empieza aquí!'
                }
              </p>
            </Col>
            <Col className="home-button-container" lg={3}>
              <Button variant="outline-secondary" className="fw-bold mb-4" disabled={!subscriptionValid} onClick={() => navigate('/questionary')} >
                { progress && progress > 0 ? "Editar Cuestionario" : "Comenzar Cuestionario" }
              </Button>
            </Col>
          </Row>
        </Container>
      </Card>
   )
}

export default QuestionarySection;

import { ReactNode } from 'react'
import './InitialLayout.scss';
import { Container, Row, Col } from 'react-bootstrap';
import MynuWhiteLogo from '../../assets/images/mynu-white-logo.png';


type InitialLayourProps = {
  children: ReactNode
}

function InitialLayout({ children }: InitialLayourProps) {
  return (
    <Container fluid>
      <Row>
        <Col className="section-left d-none d-lg-flex">
          <img src={MynuWhiteLogo} alt=""/>
        </Col>
        <Col className="section-right">
          {children}
        </Col>
      </Row>
    </Container>
  );
}

export default InitialLayout;

import { percentileType } from "../../../../models";

// constants, types
export const nonNumericSubcategories = ['Intolerancia a la lactosa', 'Masa Corporal Magra', 'Vitamina C']
export const RegularGradientSubcategories = ["Masa Corporal Magra"];
export const ExtraDecimalSubcategories = ["Tabaco", "Acído Fólico"];

export type TermometerProps = {
  prs: number;
  prsTo1: number;
  minBound: number;
  maxBound: number;
  predisposition: string;
  subcategoryName: string;
  subcatScore: number;
  percentile: percentileType | null;
}

export type PredispositionShow = "Alta" | "Media" | "Baja";

export type MusclePredispositionShow = "Ventaja" | "Normal";
export type LowHighHighPredispositionShow = "Baja" | "Alto" | "Alto";

export const NUMERIC_PRS_DESCRIPTION = "Esto indica cuántas veces eres más propenso a padecer esta condición";
export const NON_NUMERIC_PRS_DESCRIPTION = "Indica categóricamente tu predisposición";

export const rawPredispositiontoShow = new Map<string, PredispositionShow>([
  ['low', 'Baja'],
  ['medium', 'Media'],
  ['high', 'Alta']
]);

export const alterPredispositiontoShow = new Map<string, PredispositionShow>([
  ['low', 'Baja'],
  ['medium', 'Alta'],
  ['high', 'Alta']
]);

export const rawMusclePredispositionToShow = new Map<string, MusclePredispositionShow>([
  ['low', 'Ventaja'],
  ['medium', 'Normal'],
  ['high', 'Normal']
]);

export const validPredispositions = [
  "low",
  "medium",
  "high"
];

export enum SubCategoriesBounds {
  NON_NUMERIC_REGULAR_GRADIENT_LOW = "Ventaja",
  NON_NUMERIC_REGULAR_GRADIENT_MEDIUM = "Normal",
  NON_NUMERIC_REGULAR_GRADIENT_HIGH = "Normal",
  NON_NUMERIC_GRADIENT_LOW = "Baja",
  NON_NUMERIC_GRADIENT_MEDIUM = "Media",
  NON_NUMERIC_GRADIENT_HIGH = "Alta",
}

export const PERCENTILE_NOT_VALID = -1;
export const REPLACE_NUMBER_PRS = "<REPLACE_NUMBER_PREDISPOSITION>";
export const REPLACE_NUMBER_RISK = '<REPLACE_NUMBER_RISK>';


export const Green = "#77FAD7";
export const Purple = "#6E6EFF";
export const Red = "#FA7E9F";

export const HIDDEN_SUBCATEGORIES = [
  'vitamins_a',
  'vitamins_folicacid',
  'vitamins_b12',
  'wellness_tobbaco',
  'allergies_general'
]

export const CATEGORIES_SOON = [
  'Tipo de dieta según tu genética'
]

export const SUBCATEGORIES_SOON: categorySubcategories[] = [
  { categoryName: 'wellness', subcategories: ['Próximamente'] },
  { categoryName: 'allergies', subcategories: ['Próximamente'] },
  { categoryName: 'vitamins', subcategories: ['Próximamente'] }
]

export type categorySubcategories = {
  categoryName: string,
  subcategories: string[]
}
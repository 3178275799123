import { ReactNode } from 'react';
import Card from 'react-bootstrap/Card';

interface StepperComponentProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  stepHeaders: string[];
  steps: ReactNode[];
  children?: ReactNode;
}

export default function StepperComponent(props: StepperComponentProps) {
  const {
    activeStep,
    stepHeaders,
    steps,
    children,
  } = props;
  return (
    <div className='stepper-component'>
      <div className='steps-desktop'>
        {stepHeaders.map((stepHeader, index) => {
          return (
            <div className='step-container' key={`${stepHeader}-${index}`}>
              <div className='step-container-circle' key={`${stepHeader}-${index}`}>
                <div key={index} className={`step-circle ${activeStep > index ? 'active' : activeStep === index ? 'highlighted' : ''}`}>
                  {index + 1}
                </div>
                <div className={`step-text mynu-bold ${activeStep === index ? 'text-decoration-underline' : ''}`}>
                  {stepHeader}
                </div>
              </div>
              {index !== steps.length - 1 && <div className={`${activeStep > index ? 'hfill-colored' : 'hfill'}`} />}
            </div>
          )
        })
        }
      </div>
      <div className='stepper'>
        <Card className='stepper-body'>
          <Card.Header>
            <div className='header-text'> {stepHeaders[activeStep]} </div>
          </Card.Header>
          <Card.Body className='d-flex flex-column justify-content-center'>
            {steps[activeStep]}
          </Card.Body>
        </Card>
        
        {children}

      </div>
    </div>
  )
}
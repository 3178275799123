/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Config, EnvType } from './config-types';


// IMPORTANTE: Definir acá las variables de entorno extraidas desde process.env
const envs: any = {
  REACT_APP_NAME: process.env.REACT_APP_NAME,
  REACT_APP_VERSION_HASH: process.env.REACT_APP_VERSION_HASH,
  REACT_APP_ENV: process.env.REACT_APP_ENV
};

function isNotBlank(str: string = ''): boolean {
  return !/^\s*$/.test(str);
}

function envString(name: string, defaultValue = ''): string {
  const val = envs[name];
  return isNotBlank(val) ? String(val) : defaultValue;
}

function envBoolean(name: string, defaultValue: boolean = false): boolean {
  const val = envString(name, '');
  if (val === 'true') return true;
  if (val === 'false') return false;
  return defaultValue;
}

function envNumber(name: string, defaultValue: number = 0): number {
  const val = envString(name, '');
  if (val === '') return defaultValue;
  const n = Number(val);
  return !isNaN(n) ? n : defaultValue;
}

const appEnv: EnvType = envString('REACT_APP_ENV', 'local') as EnvType;

// configuraciones con tipado explicito
const configDefault: Config = {

  appName: envString('REACT_APP_NAME', 'MyNu'),
  versionHash: envString('REACT_APP_VERSION_HASH', '0'),
  appEnv,

  // perfiles de ambientes
  profiles: {
    local: appEnv.startsWith('local'),
    dev: appEnv.startsWith('dev'),
    qa: appEnv.startsWith('qa'),
    prod: appEnv.startsWith('prod'),
    test: appEnv.startsWith('test')
  },
  urlBackend: {
    local: 'http://localhost:3000/rest',
    dev: 'https://ukpvs1lo24.execute-api.us-east-1.amazonaws.com/dev/rest/',
    prod: 'https://3wz72cmklk.execute-api.us-east-1.amazonaws.com/prod/rest',
  }
};

export default configDefault;

import { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { BuyKitFormProps } from "../../BuyKitPage";
import { DISCOUNT_PRICE_CLP, KIT_PRICE_CLP } from "../../constants";
import { QuestionCircleFill } from "react-bootstrap-icons";

export default function KitStep(props: BuyKitFormProps) {
  const {
    formData,
    setFormData,
    kitData,
    attributeValidations,
    setAttributeValidations,
  } = props;

  const {
    kitName,
    kitDescription,
    kitImage,
  } = kitData;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;

    const newValues = {
      ...formData,
      [name]: name === 'acceptedConditions' ? checked : value,
    }
    setFormData(newValues);

    const newValidation = {
      ...attributeValidations,
      [name]: Boolean(!value)
    }

    if (name === 'kitQuantity' && parseInt(value) > 0) {
      newValidation.kitQuantity = !value;
    }

    if (name === 'acceptedConditions') {
      newValidation.acceptedConditions = !checked;
    }

    setAttributeValidations(newValidation);
  };

  const handleSelectorChange = (paramName: string, value: string) => {
    const newValues = {
      ...formData,
      [paramName]: value,
    }

    const newValidation = {
      ...attributeValidations,
      [paramName]: Boolean(!value)
    }

    if (paramName === 'kitQuantity' && parseInt(value) > 0) {
      newValidation.kitQuantity = Boolean(!value)
    }

    setAttributeValidations(newValidation);
    setFormData(newValues);
  }

  const [show, setShow] = useState(true);

  return (
    <div className="stepper-items-container">
      <div className="item">
        <div className="item-content">
          <div className="item-element">
            <img src={kitImage} alt={kitData.kitName} className="thumb-img" />
          </div>
          <div className="item-element">
            <div className="item-header-text" style={{ cursor: "pointer" }}>
              {kitName}
              &nbsp;
              <QuestionCircleFill className="info-icon" onClick={() => setShow(true)} />  
            </div>
            <Markdown remarkPlugins={[remarkGfm]} className='item-description'>{kitDescription}</Markdown>
          </div>
          <div className="item-element justify-content-start d-flex flex-column align-items-end">
            <div className="item-header-text">Precio</div>
            <div className="align-self-end">
              <div className="item-price right"><s>{KIT_PRICE_CLP.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</s></div>
              <div className="item-price right color-purple">{DISCOUNT_PRICE_CLP.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</div>
            </div>
          </div>
        </div>
        <div className="item-content mt-2 align-items-center">
          <div className="item-element w-30" >
            <Form.Group className="my-2" controlId={'buy-kit-form-kitQtty'}>
              <Form.Select
                required
                name="kitQuantity"
                value={formData.kitQuantity}
                onChange={(event) => handleSelectorChange('kitQuantity', event.target.value)}
                isInvalid={attributeValidations.kitQuantity}
                className={(formData.kitQuantity && !attributeValidations.kitQuantity ? 'is-valid' : '')}
              >
                {Array.from(Array(11).keys()).map((index) => (
                  <option key={index} value={index}>{index}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Debes ingresar el número de kits de adn que deseas comprar</Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="item-element">
            <div className="item-price" style={{ textWrap: 'nowrap' }}>Total a pagar</div>
            <div className="item-price right color-purple">
              {(DISCOUNT_PRICE_CLP * formData.kitQuantity).toLocaleString("es-CL", { style: "currency", currency: "CLP" })}
            </div>
          </div>
        </div>
      </div>

      <div className="item">
        <Form.Group className="mb-2 d-flex justify-content-center" controlId="buy-kit-formTerms">
          <div className="item justify-content-center">
            <Form.Check
              required
              type="checkbox"
              name="acceptedConditions"
              label={<>Acepto los <a href={"https://mynu.ai/terminos/"} target={'_blank'} rel='noreferrer'>términos y condiciones</a></>}
              isInvalid={attributeValidations.acceptedConditions}
              onChange={handleChange}
            />
          </div>
        </Form.Group>
      </div>
  
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Info sobre el {kitData.kitName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Por la compra de un {kitData.kitName} te llevas</p>
          <span><Markdown remarkPlugins={[remarkGfm]}>{kitData.kitDescription}</Markdown></span>
          <p>Los 3 meses premium incluyen además el uso de nuestra aplicación:</p>
          <ul>
            <li>Dieta personalizada</li>
            <li>Chat con Numy, nuestra IA especialista en nutrición </li>
            <li>Tus resultados genéticos</li>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  )
}

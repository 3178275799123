import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";

type MealOptionsModalProps = {
  day: string;
  menuName: string;
  show: boolean;
  onHide: () => void;
  mealOptions: string[][];
  selectMealOption: (day: string, menuName: string,newMenu: string[]) => Promise<void>;
}

function MealOptionsModal ({ day, menuName, show, onHide, mealOptions, selectMealOption }: MealOptionsModalProps) {
 return (
  <Modal
    size="lg"
    show={show}
    onHide={onHide}
    aria-labelledby="contained-modal-title-vcenter"
    className="meals-modal-container"
  >
    <Modal.Header closeButton className="border-bottom-0 options-modal-header">
      <Modal.Title id="contained-modal-title-vcenter" className="meals-modal-title pt-4">
        Selecciona una de las opciones de {menuName} para cambiar en tu dieta
      </Modal.Title>
    </Modal.Header>
  <Modal.Body className="grid-example">
    <Container>
      <Row className="pb-3">
        {mealOptions.map((option, index) => {
          return (
            <Col xs={12} lg={4} key={`option-${index + 1}`}>
              <Card className="meal-card option-card">
                <span className="option-title">
                  Opción {index + 1}
                </span>
                <ul className="mt-3">
                  {option.map((food) => {
                    return (
                      <li key={food}>{food}</li>
                    )
                  })}
                </ul>
              </Card>
              <div className="text-center d-grid px-5">
                <Button
                  variant="outline-primary"
                  className="mt-3"
                  onClick={() => selectMealOption(day, menuName, option)}
                >
                  Seleccionar
                </Button>
              </div>
            </Col>
          )
        })}
      </Row>
    </Container>
  </Modal.Body>
</Modal>
 )
}

export default MealOptionsModal;

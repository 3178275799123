import { axiosInstance } from "../../api/http";
import { Subscription } from "../../models";

export type ReveniuPaymentData = {
	id: number,
	completion_url: string,
	security_token: string
}

export type AnnymousOneTimePaymentInformation = {
  kitQuantity: number;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  region: string;
  province: string;
  acceptedConditions: boolean;
}

export async function getSubscription(userToken: string): Promise<Subscription | null> {
  const headers = {
    "Authorization": `Bearer ${userToken}`,
  }

  try {
    const response = await axiosInstance.get('/subscription', { headers });
    const subscription: Subscription = response.data;
    return subscription;
  } catch (e) {
    return null;
  }
}

export async function reveniuCheckout(userToken: string, planId: number): Promise<ReveniuPaymentData> {
  const headers = {
    "Authorization": `Bearer ${userToken}`,
  }
  
  const response = await axiosInstance.post('/subscription/reveniu-checkout', {planId}, { headers });
  return response.data;
}

export async function anonymousOneTimeReveniuCheckout(
    paymentInformation: AnnymousOneTimePaymentInformation): Promise<ReveniuPaymentData> {
  const response = await axiosInstance.post('/reveniu/anonymous-checkout', {...paymentInformation});
  
  return response.data;
}

export async function updateSubscription(userToken: string, subscriptionId: number): Promise<any | null> {
  const headers = {
    "Authorization": `Bearer ${userToken}`,
  }

  try {
    const response = await axiosInstance.put('/subscription', {subscriptionId}, { headers });
    return response.data;
  } catch (e) {
    return null;
  }
}
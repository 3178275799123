import { FC, useState, useEffect, useCallback } from 'react';
import {Form} from 'react-bootstrap';
import { QuestionnaireCard } from './QuestionnaireCard';

const { Group, Label, Control } = Form;

type NumberComponentProps = {
  name: string;
  className?: string;
  question: string;
  required: boolean;
  initialValue?: number;
  placeholder?: string;
  updateValue: (selectedNumber: number) => void;
  isValid: (selectedNumber: number) => boolean
  numberType?: 'integer' | 'float'
  showInvalid?: boolean;
}

const NumericComponent: FC<NumberComponentProps> =  (props: NumberComponentProps) => {
  const {
    name,
    question,
    className,
    required,
    placeholder,
    initialValue,
    updateValue,
    isValid,
    numberType,
    showInvalid
  } = props;
  const [selectedValue, setSelectedValue] = useState<number|null>(null);
  
  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue)
    } else {
      setSelectedValue(null);
    }
  }, [initialValue]);
  
  const triggerValueUpdate = useCallback( (newValue:number) => {
    setSelectedValue(newValue);
    updateValue(newValue);
  }, [updateValue]);
  
  const checkValidInput = useCallback((): boolean => {
    if (!selectedValue) return !required;
    return isValid(selectedValue);
  }, [selectedValue, isValid, required]);

  return (
    <QuestionnaireCard>
      <Group className="mb-2" controlId={name+'-numeric-input'} key={name+'-num-input'} >
        <Label className="signup-form-label mb-1">{question}</Label>
          <Control
            required={required}
            name={name}
            type='number'
            placeholder={placeholder}
            value={selectedValue ?? undefined}
            className={className ?? ''}
            isInvalid={!checkValidInput() && showInvalid}
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            onChange={(event) => {
              const newValue = numberType === 'float' ? parseFloat(event.target.value) : parseInt(event.target.value);
              if (newValue) {
                triggerValueUpdate(newValue);
              } else {
                setSelectedValue(null);
              }
            }}
          />
      </Group>
    </QuestionnaireCard>
  )
}

export default NumericComponent;
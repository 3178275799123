import { useMemo } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastBody from 'react-bootstrap/ToastBody';
import ToastContainer from 'react-bootstrap/ToastContainer';

import mynuLogo from '../../assets/images/mynu-y-icon.svg';

type ToastProps = {
  show: boolean;
  variant: string;
  text: string;
  title?: string;
  onClose: () => void;
}

function Notification({ show, variant, text, title, onClose }: ToastProps) {
  const deviceMobile = useMemo(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }, []);

  return (
    <ToastContainer
      position={deviceMobile ? 'top-center' : 'top-end'}
      className='p-3' style={{ zIndex: 100000 }}
    >
      <Toast show={show} bg={variant} onClose={onClose} className="fw-bold">
        <Toast.Header>
          <img
            src={mynuLogo}
            width='20px'
            alt='mynu-logo'
            className='me-2'
          />
          <strong className='me-auto text-capitalize'>
            {title && title.length > 0 ? title : 'Mynu'}
          </strong>
        </Toast.Header>
        <ToastBody>
          {text ?? 'Oops, ha ocurrido un error :('}
        </ToastBody>
      </Toast>
    </ToastContainer>
  )
}

export default Notification;

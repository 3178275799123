import '../../styles/App.scss';
import './SubscriptionBadge.scss';
import { Col } from "react-bootstrap";
import { Subscription } from '../../models';
import { getSubscriptionType } from '../../constants'

type SubscriptionBadgeProps = {
    subscription?: Subscription | null;
}

function SubscriptionBadge({ subscription }: SubscriptionBadgeProps) {

    const validSubscription = (subscription: Subscription) => {
        if (subscription.active === false) return false;
        if (subscription.end_date){
            const now = new Date();
            const expirationDate = new Date(subscription.end_date);
            if (now > expirationDate) return false;
        }
        return true;
    }

   return (
        <Col className="d-flex justify-content-center" sm="2">
            { subscription?.type === 'premium' && validSubscription(subscription) ? (
                <div className="badge badge-premium">
                    <span className="badge-text badge-text-premium" id={"user-badge"}>{getSubscriptionType(subscription?.type || '')}</span>
                </div>
            ) : null
            }
        </Col>
    )
}

export default SubscriptionBadge;

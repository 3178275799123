import { ReactNode } from 'react';
import AppNavbar from './AppNavbar';


type MainLayoutProps = {
  children: ReactNode,
}

function MainLayout({ children }: MainLayoutProps) {
  return (
    <>
      <AppNavbar />
      <div style={{ margin: "3rem 0 0 0" }}>
        {children}
      </div>
    </>
  );
}

export default MainLayout;

import { Button } from 'react-bootstrap';
import './FormButton.scss';

type Props = {
  text: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

function FormButton({ text, onClick, isDisabled = false }: Props ) {

  const handleClick = () => {
    if (isDisabled) return;
    if (onClick) {
      onClick();
    }
  }
  return (
    <Button
      variant="primary"
      type="submit"
      className="form-button m-3"
      disabled={isDisabled}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

export default FormButton;

import { Button, ButtonGroup, Modal, Row, Col } from 'react-bootstrap';
import PlanDetails from '../PlanDetails/PlanDetails';
import './SubscriptionPlanModal.scss';
import { useState } from 'react';
import { Subscription } from '../../models';
import { reveniuCheckout } from '../../services/subscription';
import { submitTransbank } from '../../services/payment/submitTransbank';

type BannerProps = {
    showModal: boolean;
    subscription: Subscription | null;
    onHide?: () => void;
}

function SubscriptionPlanModal({ showModal, subscription, onHide }: BannerProps) {

  const [periodicity, setPeriodicity] = useState('anual');
  const [isLoading, setIsLoading] = useState(false);

  const checkout = async (planId: number) => {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      setIsLoading(true);
      try {
        const checkoutRes = await reveniuCheckout(userToken, planId);
        submitTransbank(checkoutRes.completion_url, checkoutRes.security_token)
      }
      catch(error){
        console.log(error);
        setIsLoading(false);
      }
    }
  }

  if(subscription?.type === 'premium'){
    return  (
      <Modal size='lg' show={showModal} onHide={onHide}>
      <Modal.Header bsPrefix='modal-plan-header' closeButton>
      </Modal.Header>
      <Modal.Body>
          <Row>
              <Col className="d-flex justify-content-center" sm={{span: 8, offset: 2}}>
                  <p className='inner-title'>Escoge el plan que más se acomode a ti</p>
              </Col>
          </Row>
          <Row className='mt-3 mb-4'>
              <Col className="d-flex justify-content-center" sm={12} md={6} lg={6}>
                <PlanDetails type='premium' periodicity='monthly' isLoading={isLoading} onAccept={checkout} />
              </Col>
              <Col className="d-flex justify-content-center" sm={12} md={6} lg={6}>
                <PlanDetails type='premium' periodicity='anual' isLoading={isLoading} onAccept={checkout} />
              </Col>
          </Row>
      </Modal.Body>
    </Modal>
    )
  }

  return (
    <Modal size='xl' show={showModal} onHide={onHide}>
    <Modal.Header bsPrefix='modal-plan-header' closeButton>
    </Modal.Header>
    <Modal.Body>
        <Row>
            <Col className="d-flex justify-content-center" sm={{span: 8, offset: 2}}>
                <p className='inner-title'>Escoge el plan que más se acomode a ti</p>
            </Col>
        </Row>
        <Row>
            <Col className="d-flex justify-content-center mt-2" sm={{span: 8, offset: 2}}>
              <ButtonGroup className='periodicity'>
                <Button 
                  className='button-periodicity button-selected'
                  variant={periodicity === 'monthly' ? "primary" : "outline-primary"} 
                  onClick={() => setPeriodicity('monthly')}
                >
                  Mensual
                </Button>
                <Button 
                  className='button-periodicity button-selected'
                  variant={periodicity === 'anual' ? "primary" : "outline-primary"}
                  onClick={() => setPeriodicity('anual')}
                >
                  Anual
                </Button>
              </ButtonGroup>
            </Col>
        </Row>
        <Row>
            <Col className="d-flex justify-content-center mt-4" sm={{span: 10, offset: 1}}>
            <p className='description'>Llévate un mes de <span className='highlight'>suscripción gratis</span> por la compra de tu kit de ADN</p>
            </Col>
        </Row>
        <Row className='mt-3 mb-4'>
            <Col className="d-flex justify-content-center" sm={12} md={12} lg={4}>
              <PlanDetails type='premium' periodicity={periodicity} isLoading={isLoading} onAccept={checkout} />
            </Col>
            <Col className="d-flex justify-content-center" sm={12} md={12} lg={4}>
              <PlanDetails type='premium-kit' periodicity={periodicity} isLoading={isLoading} onAccept={checkout} />
            </Col>
            <Col className="d-flex justify-content-center" sm={12} md={12} lg={4}>
              <PlanDetails type='kit' periodicity={periodicity} isLoading={isLoading} onAccept={checkout} />
            </Col>
        </Row>
    </Modal.Body>
  </Modal>
  )

}

export default SubscriptionPlanModal;

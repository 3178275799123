import DietSummaryItem from "./DietSummaryItem";

import './DietSummaryComponent.scss';

export interface SummaryItemProps {
  dietMealRestrictions: any
}

export default function SummaryItem (props: SummaryItemProps) {
  const {
    dietMealRestrictions
  } = props;
  
  return (
    <div className='summary-container'>
      <span className="summary-desc m-3">
        Aquí encontrarás tu <b>Guía Nutricional</b> con recomendaciones hechas específicamente para ti,
        basadas en tu genética, tus condiciones médicas, hábitos y patrón alimentario
      </span>
      {
        Object.keys(dietMealRestrictions).map((key: string, index) => (
          <DietSummaryItem
            classProp={key}
            key={`${key}-${index}`}
            values={dietMealRestrictions[key]}
          />
        ))
      }
    </div>
  )
}
import kit from "../../assets/images/kit.png";

export const KIT_PRICE_CLP = 119990;
export const DISCOUNT_PRICE_CLP = 99990;

export const kitData = {
  kitName: 'Kit Genético',
  kitDescription: `
  - Test Genético
  - 3 meses Premium
  - Teleconsulta con especialista`,
  kitImage: kit,
}

import React, { FC, useState, useEffect } from 'react';
import { Button, InputGroup, ListGroup, OverlayTrigger, Popover, Form } from 'react-bootstrap';

import MynuTooltip from '../Tooltip/Tooltip';

import './SearchComponent.scss';
import { X } from 'react-bootstrap-icons';

const { Control } = Form;

type SearchOption = {
  id: string;
  label: string;
  tooltip?: string;
  icon?: string;
}

type SearchComponentProps = {
  searchText: string;
  placeholder: string;
  setSearchText: (text: string) => void;
  selectedIds: string[];
  options: SearchOption[];
  setOptions: (options: any) => void;
  filteredOptions: SearchOption[];
  required: boolean;
  useLabels?: boolean;
  useNames: boolean;
  name?: string;
  RenderItem: React.ComponentType<any>;
  renderItemType?: string;
  id: string;
  checkResultsAvailable: boolean;
  isInvalid: boolean;
  isValid: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, switchedId: string) => void;
  onClick: (clickedItemId: string) => void;
}

const SearchComponent: FC<SearchComponentProps> = ( props: SearchComponentProps ) => {
  const {
    searchText,
    placeholder,
    setSearchText,
    options,
    selectedIds,
    setOptions,
    filteredOptions,
    required,
    useLabels,
    useNames,
    name,
    RenderItem,
    renderItemType,
    checkResultsAvailable,
    isInvalid,
    isValid,
    onChange,
    onClick,
  } = props;
  
  const [popoverWidth, setPopoverWidth] = useState(0);

  const filterOptions = (search: string) => {
    const filtered = options.filter(({ label }) => 
      label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .includes(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ));
    if (search === ''){
      setOptions(options);
    } else {
      setOptions(filtered);
    }
  }

  const handleTextChange = (searchString: string) => {
    setSearchText(searchString);
    setTimeout(() => {
      filterOptions(searchString);
    }, 200);
  }

  const clearInput = () => {
    setSearchText('');
    filterOptions('');
  }

  useEffect(() => {
    const updatePopoverWidth = () => {
      const containerWidth = document.getElementById('search-container')?.offsetWidth || 0;
      setPopoverWidth(containerWidth);
    };

    // Update popoverWidth initially and on window resize
    updatePopoverWidth();
    window.addEventListener('resize', updatePopoverWidth);
    return () => {
      window.removeEventListener('resize', updatePopoverWidth);
    };
  }, []);

  const RenderResults = () => {
    return (
      <ListGroup variant='flush' >
        { filteredOptions.length > 0 ? 
          filteredOptions.map(({ id, label, tooltip, icon }: SearchOption) => (
            <ListGroup.Item key={id} action onClick={() => {onClick(id)}} >
              <RenderItem
                required={required}
                label={
                useLabels ?
                  tooltip ?
                    <MynuTooltip description={tooltip} >
                      <label className="fw-normal">{label}</label>
                    </MynuTooltip>
                    :
                    <span>
                      {icon ? <><img src={icon} alt="" width={20} /> </> : ''}
                      {label}
                    </span>
                  : id
                }
                name={useNames ? name : id}
                type={renderItemType}
                id={id}
                {
                  ...checkResultsAvailable && { checked: selectedIds.includes(id), }
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {onChange(event, id)}}
              />
            </ListGroup.Item>
          ))
          : 
          <ListGroup.Item> No se encontraron resultados similares </ListGroup.Item>
        }
      </ListGroup>
  )}

  return (
    <OverlayTrigger
      placement="bottom-start"
      overlay={options.length > 0 ? 
        <Popover id="search-results" className='custom-popover' style={{ width: `${popoverWidth}px` }} >
          <RenderResults />
        </Popover>
        : 
        <></>
      }
      trigger={"focus"}
    >
      { ({...triggerHandler}) => (
        <InputGroup style={{ alignItems: 'center' }}>
          <Control
            placeholder={placeholder}
            type="text"
            {...triggerHandler} 
            value={searchText} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e.target.value)}
            className="input-search"
            isValid={isValid}
            isInvalid={isInvalid}
          />
          {searchText && (
            <Button variant="light" onClick={clearInput} size='sm'
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                padding: '0px',
                margin: '0px',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,								
              }}
            >
              <InputGroup.Text 
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }} >
                  <X size={25}/>
              </InputGroup.Text>
            </Button>
          )}
        </InputGroup>
      ) }
    </OverlayTrigger>
  )
}

export default SearchComponent;

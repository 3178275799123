import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import FormButton from "../../components/FormButton/FormButton";

const { Group, Label, Control } = Form;

type TestModalProps = {
  show: boolean;
  onHide: () => void;
  registerTest: (testId: string) => void;
  error: string;
}

function TestRegisterModal({ show, onHide, registerTest, error }: TestModalProps) {
  const [ testId, setTestId ] = useState('');
  const [ isEmpty, setIsEmpty ] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmpty(false);
    // remove white spaces
    const kitId = event.target.value.replace(/\s+/g, '');
    setTestId(kitId)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      console.log('Registrar tubo');
      registerTest(testId);
    } else {
      setIsEmpty(true);
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      className="test-register-modal-container"
      centered
    >
      <Modal.Header closeButton className="border-bottom-0 mx-3 mt-3"></Modal.Header>
      <Modal.Body className="pt-0 pb-4 px-4 px-lg-5">
        <Modal.Title id="contained-modal-title-vcenter" className="test-modal-title text-center text-lg-start">
          Registrar el kit
        </Modal.Title>
        <p className="mt-2">
          El número se indica en el kit junto al código de barras. 
          Importante: Si decides realizar la prueba en grupo familiar o en un entorno empresarial, es fundamental asegurarse de que la muestra de saliva del kit corresponda a la persona registrada en la plataforma para la prueba de ADN.
        </p>
        <div className="input-test-container">
          <Form noValidate onSubmit={handleSubmit} className="mt-4">
            <Group className="mb-2" controlId='testId'>
              <Label className="mb-1 fw-bold">Test id</Label>
            <Control
              required 
              name="code"
              type="text"
              placeholder="6 a 10 carácteres alfanuméricos"
              value={testId}
              onChange={handleChange}
              isInvalid={(error || isEmpty) ? true : false}
              //className={success ? 'is-valid' : ''}
            />
            <Control.Feedback type="invalid">
              {error ? error : 'Este campo es obligatorio.'}
            </Control.Feedback>
            </Group>
            <div className="text-center">
              <FormButton text="Registrar tubo" />
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TestRegisterModal;

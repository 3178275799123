import './SuccessMessage.scss';
import HappyStudent from '../../assets/images/happy-student.svg';

type SuccessMessageProps = {
  text: string;
  to?: string;
  description?: string;
  textLink?: string;
}

function SuccessMessage({ text, to = '/login', description = '', textLink = 'Continuar' }: SuccessMessageProps) {
  return (
    <div className="questionnaire-success-container">
      <div className="text-center">
        <img src={HappyStudent} alt="" width={280} />
      </div>
      <h1 className="text-center text-title">
        {text}
      </h1>
      <div className='text-center m-4 pt-2'>
        {description}
      </div>
      <div className="success-button-container">
        <a href={to} className="btn btn-primary mt-4 continue-button">
          {textLink}
        </a>
      </div>
    </div>
  )
}

export default SuccessMessage;

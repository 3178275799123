import { StateCreator } from "zustand";
import { UserStore } from "../interfaces/user";
import { AppState } from "../interfaces";
import { User } from "../../models";

export const createUserSlice: StateCreator<
  AppState,
  [],
  [],
  UserStore
> = (set, get) => ({
  userData: null,
  isLoadingUserData: false,
  setIsLoadingUserData: (l: boolean) => set(() => ({ isLoadingUserData: l })),
  setUserData: (u: User | null) => set(() => ({ userData: u })),
});
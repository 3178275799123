import { FC, useState, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';

import { SelectComponentProps } from './SelectComponent';

const { Group, Select } = Form;

const NO_SELECTION_ID = '-';
const NO_SELECTION_VALUE = '';

type CustomDropDownComponentProps = SelectComponentProps & {
  maxWidth?: string;
}

const CustomDropDownComponent: FC<CustomDropDownComponentProps> = (props: CustomDropDownComponentProps) => {
  const {
    name,
    options,
    required,
    initialValue,
    updateValue,
    isValid,
    showInvalid
  } = props;
  const [selectedId, setSelectedId] = useState<string | null>(null);

  useEffect(() => {
    setSelectedId(initialValue ?? null);
  }, [initialValue]);

  const checkValidInput = useCallback((): boolean => {
    if (isValid && selectedId) return isValid(selectedId);
    if (!selectedId) return false;
    return selectedId !== '';
  }, [selectedId, isValid]);

  const triggerValueUpdate = useCallback((newId: string) => {
    setSelectedId(newId);
    updateValue(newId);
  }, [updateValue]);

  return (
    <Group controlId={`custom-drop-down-${name}`} className='mb-1'>
      <Select
        aria-label="Select"
        style={{ 
          maxWidth: props.maxWidth ?? '100%',
          maxHeight: '3em',
        }}
        name={name}
        required={required}
        value={options.find((option) => option.id === selectedId)?.label ?? NO_SELECTION_VALUE}
        onChange={(event) => {
          const newValue = event.target.value;
          const newId = options.find((option) => option.label === newValue)?.id;
          if (newId) {
              triggerValueUpdate(newId);
          }
        }}
        isInvalid={!checkValidInput() && showInvalid}
      >
        {([{ id: NO_SELECTION_ID, label: NO_SELECTION_VALUE }].concat(options)).map(({ id, label }) => {
          return (<option key={id} value={label}> {label} </option>);
        })}
      </Select>
    </Group>
  )
}

export default CustomDropDownComponent;
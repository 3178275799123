import { useEffect, useState } from 'react';
import CheckBoxComponent, { CheckBoxOption } from './CheckBoxComponent';


type CheckBoxOneOptionComponentProps = {
  name: string;
  question: string;
  options: CheckBoxOption[];
  initialValue?: string;
  showInvalid: boolean;
  updateValue: (selectedId: string) => void;
  updateNoSelection?: () => void
};

const NO_OPTION_ID = 'NINGUNA_OPCION_CON_ESTE_ID'+(new Date().getTime().toString());

export const CheckBoxOneOptionComponent: React.FC<CheckBoxOneOptionComponentProps> = (
  {
    name,
    question,
    options,
    updateValue,
    updateNoSelection,
    initialValue,
    showInvalid
  }) => {
    
  const [selectedId, setSelectedId] = useState<string | undefined>(initialValue);
  
  useEffect(() => {
    if (initialValue) {
      setSelectedId(initialValue);
    }
  }, [initialValue]);
  
  return <CheckBoxComponent
    name={name}
    question={question}
    required={true}
    options={[...options, {id: NO_OPTION_ID, label: 'Ninguna de las anteriores'}]}
    description='Selecciona una opción'
    initiallySelectedIds={selectedId ? [selectedId] : ([NO_OPTION_ID])}
    updateSelectedIds={(selectedIds) => {
      if (selectedIds.length >= 1) {
        const newElement = selectedIds[selectedIds.length-1];
        if (newElement!==NO_OPTION_ID) {
          setSelectedId(newElement);
          updateValue(newElement);
        } else {
          if (updateNoSelection) updateNoSelection();
          setSelectedId(NO_OPTION_ID);
        }
      }
    }}
    showInvalid={showInvalid}
    isValid={(selectedIds) => selectedIds.length === 1}
  />
};

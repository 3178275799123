import atom from "../../../assets/icons/landing-icons/features/atom.png";
import clock from "../../../assets/icons/landing-icons/features/clock.png";
import medic from "../../../assets/icons/landing-icons/features/medic.png";
import pc from "../../../assets/icons/landing-icons/features/pc.png";
import utensils from "../../../assets/icons/landing-icons/features/utensils.png";

export const FeaturesComponent = () => {
  return (
    <div className="features">
      <div className="pill-container">
        <div className='flex-c align-items-start justify-content-center m-4'>
          <div className="d-flex align-items-center pill capitalized spaced-1">
            <div>¿Qué Incluye?</div>
          </div>
        </div>
      </div>

      <div className="flex-container mobile-reverse" style={{width:'100%',}}>
        <div className="height-responsive bg-p1 width-responsive">
          <div className="flex-c content-center">
            <img src={atom} alt="atom" className='icon m-2'/>
            <div className="feature-title subsub-text">Kit Genético</div>
          </div>
        </div>
        <div className="height-responsive bg-p2 width-responsive">
          <div className="flex-c content-center">
            <img src={utensils} alt="atom" className='icon m-2'/>
            <div className="feature-title subsub-text">PLAN DE ALIMENTACIÓN</div>
            <div className="feature-title subsub-text">100% PERSONALIZADO Y FLEXIBLE</div>
          </div>
        </div>
      </div>
      <div className="flex-container" style={{width:'100%',}}>
        <div className="height-responsive bg-p2 width-responsive">
          <div className="flex-c content-center">
            <img src={medic} alt="atom" className='icon m-2'/>
            <div className="feature-title subsub-text">TELECONSULTA</div>
            <div className="feature-title subsub-text">NUTRICIONAL</div>
          </div>
        </div>
        <div className="height-responsive bg-p3 width-responsive">
          <div className="flex-c content-center">
            <img src={clock} alt="atom" className='icon m-2'/>
            <div className="feature-title subsub-text">ATENCIÓN 24/7</div>
          </div>
        </div>
        <div className="height-responsive bg-p1 width-responsive">
          <div className="flex-c content-center">
            <img src={pc} alt="atom" className='icon m-2'/>
            <div className="feature-title subsub-text">ACOMPAÑAMIENTO Y</div>
            <div className="feature-title subsub-text">SEGUIMIENTO</div>
          </div>
        </div>
      </div>

    </div>
  )
}
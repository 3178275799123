import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import SuccessPayment from '../../components/SuccessPayment/SuccessPayment';
import { useEffect, useState } from 'react';
import { FadeLoader } from 'react-spinners';
import { trackEvent } from '../../pixel';


function Subscription() {
  const [queryParameters] = useSearchParams()
  const [status, setStatus] = useState<string>('success');

  useEffect(() => {
    updateSuccess();
  })

  const updateSuccess = () => {
    if (queryParameters.get("success") === "loading") setStatus('loading');
    if (queryParameters.get("success") === "false") {
      setStatus('error');
      trackEvent('track', 'ViewContent', {
        content_name: 'PurchaseFailed',
        content_category: 'purchase',
        content_type: 'kit_purchase',
        content_id: 'purchase_failed'
      })
    } else if (queryParameters.get("success") === "true") {
      setStatus('success');
      trackEvent('track', 'ViewContent', {
        content_name: 'PurchaseSuccessful',
        content_category: 'purchase',
        content_type: 'kit_purchase',
        content_id: 'purchase_successful'
      })
    };
  }


  if (status === 'loading') {
    return (
      <Container fluid>
        <Col sm={{ span: 1, offset: 6 }}>
          <Row className='mt-5'>
            <FadeLoader
              color="#34B391"
              loading={true}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Row>
        </Col>
      </Container>
    )
  }
  if (status === 'success') {
    return (
      <Container>
        <Row>
          <Col>
            <SuccessPayment
              text="¡Bienvenido al siguiente nivel 🚀!"
              description={'Ahora disfrutas de Mynu, con todas las ventajas que te mereces.'}
              to="/"
              textLink="Aceptar"
              success={true}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  else {
    return (
      <Container>
        <Row>
          <Col>
            <SuccessPayment
              text="Vaya, algo no salió como esperábamos."
              description="No te preocupes, tu tarjeta no ha sido cargada. Por favor, intentalo nuevamente."
              to="/"
              textLink="Ir a Inicio"
              success={false}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Subscription;
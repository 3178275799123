import { VideoComponent } from "./VideoComponent"
import capture_1 from "../../../assets/images/phone-captures/1.png";
import capture_2 from "../../../assets/images/phone-captures/2.png";
import capture_3 from "../../../assets/images/phone-captures/3.png";
import capture_4 from "../../../assets/images/phone-captures/4.png";
const SOFTWARE_VIDEO_SRC = "https://www.youtube.com/embed/iDa102XoQHA";


const weight = "font-weight: 700;";

const phone_captures = [
  {
    text: `<span style="color:#FFBE0B; ${weight}">RESULTADOS GENÉTICOS</span>&nbsp;<span>asociados a nutrición</span>`,
    image: capture_1
  },
  {
    text: `<span style="color:#FFBE0B; ${weight}">MENÚ</span>&nbsp;<span>en base a tus objetivos, hábitos, preferencias y genética</span>`,
    image: capture_2
  },
  {
    text: `<span style="${weight}">Asesoramiento 24/7 con</span>&nbsp;<span style="color:#FFBE0B; ${weight}">NUMY</span>&nbsp;<span>, IA entrenada en nutrición y genética</span>`,
    image: capture_3
  },
  {
    text: `<span style="color:#FFBE0B; ${weight}">TELECONSULTA</span>&nbsp;<span>mensual con un nutricionista</span>`,
    image: capture_4
  }
]


export const InfoSoftware = () => {
  return (
      <div className="flex-c info-software">
        <VideoComponent
        src={SOFTWARE_VIDEO_SRC}
          videoTitle="lab"
          headerText="PLATAFORMA FÁCIL Y DINÁMICA"
          subHeaderText="¿cómo funciona?"
        />
      
        <div className="pill capitalized spaced-1"> El software </div>
        <div className="bicolor-bg">
          <div className="flex-container capture-container">
            {
              phone_captures.map((capture, index) => (
                <div key={index} className="phone-capture width-responsive">
                  <img src={capture.image} alt={"phone-capture" + index} className="phone-image"/>
                  <div className="phone-capture-text p-2" dangerouslySetInnerHTML={{__html: capture.text}}></div>
                </div>
              ))
            } 
          </div>
        </div>

    </div>
  )
}
import { ReactNode } from 'react'
import Header from './Header'

export type BuyKitFormProps = {
  children: ReactNode
}

export default function BuyKitLayout({ children }: BuyKitFormProps) {
  return (
    <div className='buy-kit-layout'>
      <Header />
      {children}
    </div>
  )
}
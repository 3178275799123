import { useNavigate } from 'react-router';
import { Button } from 'react-bootstrap';
import { ArrowLeftShort } from 'react-bootstrap-icons';
import './BackButton.css';

type BackButtonProps = {
  text: string;
}

function BackButton({ text }: BackButtonProps) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  return (

    <Button onClick={goBack} variant="link" className="back-button">
      <ArrowLeftShort size={26} />{text}
    </Button>
  )
}

export default BackButton;
import { StateCreator } from 'zustand';
import { Meal } from '../../models/diet-model';
import { AppState, DietStore } from '../interfaces';

export const createDietSlice: StateCreator<
  AppState,
  [],
  [],
  DietStore
> = (set, get) => ({
   // diet information
  dietInformation: [] as Meal[][],
  setDietInformation: (d: Meal[][]) => set(() => ({ dietInformation: d})),
  isLoadingDiet: false,
  setIsLoadingDiet: (l: boolean) => set(() => ({ isLoadingDiet: l })),
  dietStatus: '',
  setDietStatus: (s: string) => set(() => ({ dietStatus: s })),
  dietCreationFailed: false,
  setDietCreationFailed: (d: boolean) => set(() => ({ dietCreationFailed: d })),
  dietMealRestrictions: {},
  setDietMealRestrictions: (r: any) => set(() => ({ dietMealRestrictions: r })),
  hasDiet: () => !!get().dietInformation,
});


export const submitTransbank = (url: string, token: string) =>{
  var form = document.createElement('form');
  form.method = 'POST';
  form.action = url;
  form.target = '_self';
  var input = document.createElement('input');
  input.id = 'TBK_TOKEN';
  input.name = 'TBK_TOKEN';
  input.type = 'hidden';
  input.value = token;
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
}
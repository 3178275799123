import { useNavigate } from "react-router-dom";
import { MynuButtonComponent } from "./ButtonComponent"

import InfoDeliveryMobile from '../../../assets/images/info-delivery-mobile.png';
import InfoDeliveryWeb from '../../../assets/images/info-delivery-web.jpg';

export const InfoDelivery = () => {
  const navigate = useNavigate();
  return (
    <div className="info-delivery">
      <div className="flex-c align-items-start">
        <div className="pill capitalized spaced-1">delivery kit</div>
      </div>
      {/* mobile */}
      <div className="mobile-img-container flex-c content-center">
        <img src={InfoDeliveryMobile} width={'100%'} alt="info-delivery-mobile" />
      </div>
      {/* web */}
      <div className="web-img-container flex-c content-center">
        <img src={InfoDeliveryWeb} width={'100%'} alt="info-delivery-web" />
      </div>

      <div className="flex-c content-center">
        <MynuButtonComponent
          text="COMPRA TU KIT PARA COMENZAR"
          minWidth="350px"
          minHeight="60px"
          onClick={()=> navigate('/buy-kit')}
        />
      </div>
    </div>
  ) 
}
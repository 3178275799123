import { NewUser, ConfirmationData, LoginData, AuthenticationResult, ChangePasswordData, resendConfirmationCodeData } from '../../models';
import { axiosInstance } from '../../api/http';
import { AxiosResponse } from 'axios';

export async function registerNewUser(user: NewUser): Promise<void> {
  try {
    await axiosInstance.post('/auth/signup', user);
  } catch (error: any) {

    let message: string = error?.response?.data?.message || error.message

    if (message.includes('UsernameExistsException')) {
      message = 'Tu correo ya está registrado';
    } else if (message.includes('Invalid phone number format')) {
      message = 'Número de teléfono inválido. Ejemplo: +56912345678';
    } else {
      message = 'Ocurrió un problema';
    }

    throw new Error(message);
  }
}

export async function sendConfirmationCode(data: ConfirmationData): Promise<void> {
  try {
    await axiosInstance.post('/auth/confirm-signup', data);
  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message

    if (message.includes('CodeMismatchException')) {
      message = 'Código de verificación incorrecto. Prueba nuevamente.';
    } else {
      message = 'Ocurrio un problema al verificar el código';
    }

    throw new Error(message);
  }
}

export async function getUserToken(data: LoginData): Promise<AuthenticationResult> {
  try {
    const response: AxiosResponse = await axiosInstance.post('/auth/signin', data);
    return response.data.AuthenticationResult as AuthenticationResult
  } catch (error: any) {
    throw new Error(error?.response?.data?.message || error.message);
  }
}

export async function requestPasswordChange(username: string): Promise<void> {
  try {
    await axiosInstance.post('/auth/forgot-password', { username });
  } catch (error: any) {
    throw new Error(error?.response?.data?.message || error.message);
  }
}

export async function changePassword(data: ChangePasswordData): Promise<void> {
  try {
    await axiosInstance.post('/auth/confirm-forgot-password', data);
  } catch (error: any) {
    let message: string = error?.response?.data?.message || error.message
    if (message.includes('Invalid code') || message.includes('CodeMismatchException')) {
      message = 'Código de verificación incorrecto. Prueba nuevamente.';
    } else {
      message = 'Ocurrió un error al actualizar tu contraseña';
    }
    throw new Error(message);
  }
}

export async function resendConfirmationCode(data: resendConfirmationCodeData): Promise<void> {
  try {
    await axiosInstance.post('auth/resend-confirmation-code', data)
  } catch (error) {
    let message = 'Ocurrió un error al reenviar código tu contraseña';
    throw new Error(message);
  }
}

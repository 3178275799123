import { Button, Card, Col, Container, Row, ProgressBar } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from "../../components/AuthContext";
import { useStateStore } from "../../store/store";
import ResultsIcon from '../../assets/icons/kit-icon.svg';
import OneCircle from '../../assets/icons/one.svg';
import TwoCircle from '../../assets/icons/two.svg';
import ThreeCircle from '../../assets/icons/three.svg';

type ResultSectionProps = {
  status: string;
}

type TestStatus = {
  value: string;
  progress: number;
  title: string;
  subtitle: string;
}

const TEST_STATUS: TestStatus[] = [
  {
    value: 'Sample arrived',
    progress: 25,
    title: 'Resultado pendiente',
    subtitle: 'Tu solicitud ha sido recibida y está en espera de ser procesada. Te notificaremos una vez que el proceso comience. ¡Gracias por tu paciencia!',
  },
  {
    value: 'Sample being processed',
    progress: 50,
    title: 'Resultado en proceso',
    subtitle: '¡Estamos trabajando en ello! Tu solicitud está siendo procesada y pronto tendrás tus resultados. Mantente atento a las actualizaciones.',
  },
  {
    value: 'Sample processed',
    progress: 100,
    title: '¡Tus resultados ya están listos! Revísalos',
    subtitle: 'Accede ahora para ver un desglose completo de tus resultados y entender mejor como funciona tu ADN en relación a tu alimentación.'
  }
]

function ResultSection({ status }: ResultSectionProps) {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { hasResults } = store;
  
  let currentStatus;
  status = status.split('/')[0].trim()
  if (TEST_STATUS.filter(tStatus => tStatus.value === status).length > 0) {
    currentStatus = TEST_STATUS.filter(tStatus => tStatus.value === status)[0]
  }

  const resultsAvailable = () => {
    return currentUser && hasResults() && currentUser?.resultsDate
  }

  return (
    (currentStatus) ?
      <Card className="py-4 px-3 pe-lg-0 mb-4 home-card">
        <div className="d-flex align-items-start align-items-lg-center">
          <img src={ResultsIcon} alt="" width={40} className="me-3" />
          <h2 className="home-title subtitle">
            {currentStatus.title}
          </h2>
        </div>
        <Container>
          <Row>
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <ProgressBar now={currentStatus.progress} className="results-progress mt-3" />
                <span className="results-progress-label">{currentStatus.progress}%</span>
              </div>
              <p className="home-text mt-3 text-center text-lg-start">
                {currentStatus.subtitle}
              </p>
            </Col>
            {currentStatus.progress === 100 && resultsAvailable() &&
              <Col className="home-button-container" lg={3}>
                <Button variant="outline-primary" className="fw-bold" onClick={() => navigate('/results')}>Ver mis resultados</Button>
              </Col>
            }
          </Row>
        </Container>
      </Card>
      :
      (
        <Card className="p-4 mb-4 home-card">
          <div className="d-flex align-items-center">
            <img src={ResultsIcon} alt="" width={40} className="me-3" />
            <h2 className="home-title subtitle">
              ¿Qué pasa cuando adquieres tu test de ADN?
            </h2>
          </div>
          <p className="home-text mt-3">{
            `Tras la prueba, analizaremos el biomaterial y cargaremos los resultados en su cuenta personal. 
          Su información genética está protegida de forma segura. No compartimos los datos de nuestros clientes con terceros. 
          Una vez que tengas los resultados de tu test genético y hayas realizado tu cuestionario de perfilamiento podrás acceder a tu dieta personalizada.`
          }</p>
          <ul className="steps-list ps-0 mt-2">
            <li className="pb-3"><img src={OneCircle} alt="" className="me-3" />Abrir kit de ADN</li>
            <li className="pb-3"><img src={TwoCircle} alt="" className="me-3" />Realiza la prueba</li>
            <li className="pb-3"><img src={ThreeCircle} alt="" className="me-3" />Revisa tus resultados</li>
          </ul>
        </Card>
      )
  )
}

export default ResultSection;

import { Modal, Col, Row, Button } from 'react-bootstrap';
import SubscribeImage from '../../assets/images/subscribe.png';
import './SubscriptionModal.scss';

type ModalProps = {
    show: boolean;
    onClose: () => void;
    onAccept: () => void;
}

function Notification({ show, onClose, onAccept }: ModalProps) {
  return (
    <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
        <Row className='modal-body'>
            <Col className="d-flex justify-content-center" sm={{span: 10, offset: 1}}>
                <img src={SubscribeImage} alt="" width={200} />
            </Col>
        </Row>
        <Row>
            <Col className="d-flex justify-content-center mt-4" sm={{span: 8, offset: 2}}>
                <p className='inner-title'>¡Suscríbete para acceder cuantas veces quieras!</p>
            </Col>
        </Row>
        <Row>
            <Col className="d-flex justify-content-center mt-2" sm={{span: 10, offset: 1}}>
            <p className='inner-text'>Suscríbete, accede a tus dietas personalizadas y a tu asesor Numy, siempre que lo necesites.</p>
            </Col>
        </Row>
        <Row>
            <Col className="d-flex justify-content-center mt-2" sm={{span: 10, offset: 1}}>
                <Button className="btn btn-primary button-subscribe" onClick={onAccept}>Ver planes</Button>
            </Col>
        </Row>
    </Modal.Body>
  </Modal>
  )
}

export default Notification;

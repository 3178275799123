
export const SUGGESTION_GENETIC_RESULTS_QUESTION = 'En base a mis resultados genéticos ¿qué no debo comer?';
export const SUGGESTION_CHANGE_DIET = 'Cambiar dieta';
export const SUGGESTION_CALCULATE_CALORIC_NEEDS = '¿Puedes calcular mis necesidades calóricas del día en base a mi perfil?';
export const SUGGESTION_HEALTHY_MEALS = '¿Me puedes entregar ejemplos de comidas saludables?';
export const SUGGESTION_EXPLAIN_SUBCATEGORY_CARBS_ASIMILATION = 'Explica la subcategoría \'Asimilación de Carbohidratos\''
export const SUGGESTION_EXPLAIN_PRS = '¿Que significa PRS?'

export const WELCOME_MESSAGE_CONTENT = '¡Hola! Soy Numy, tu asistente nutricional. ¿En qué puedo ayudarte hoy?';


export const suggestions = [
  {
    question: SUGGESTION_HEALTHY_MEALS,
    description: 'Muestra recetas saludables, por favor.',
    summary: 'Ejemplos de comidas saludables'
  },
  {
    question: SUGGESTION_EXPLAIN_PRS,
    description: SUGGESTION_EXPLAIN_PRS,
    summary: SUGGESTION_EXPLAIN_PRS
  },
  {
    question: SUGGESTION_EXPLAIN_SUBCATEGORY_CARBS_ASIMILATION,
    description: SUGGESTION_EXPLAIN_SUBCATEGORY_CARBS_ASIMILATION,
    summary: SUGGESTION_EXPLAIN_SUBCATEGORY_CARBS_ASIMILATION
  },
];


import { useEffect, useRef, useState } from "react";
import { Badge, Card, Container, Overlay, Tooltip } from "react-bootstrap";

import MainLayout from "../../components/MainLayout/MainLayout";
import { useCurrentUser } from "../../components/AuthContext";

import { InterpretedResults } from "../../models";
import ResultsCategories from "./components/resultsCategories/ResultsCategories";

import './ResultPage.scss';
import { useStateStore } from "../../store/store";
import { loadUserInfo } from "../../store/loader";
import { isEmptyOrUndefined } from "../../utils";
import IMAGE_RESULTS_SRC from "../../assets/images/gen_results_tutorial.webp";
import IMAGE_RESULTS_MOBILE_1_SRC from "../../assets/images/gen_results_tutorial_mobile_1.webp";
import IMAGE_RESULTS_MOBILE_2_SRC from "../../assets/images/gen_results_tutorial_mobile_2.webp";

function ResultPage() {
  const store = useStateStore();
  const { resultMealsRestrictions, hasResults, resultsData } = store;

  const currentUser = useCurrentUser();
  const [data, setData] = useState<InterpretedResults | null>();

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (currentUser?.id && userToken) {
      if (!hasResults()) {
        loadUserInfo(store, currentUser);
      } else {
        setData(resultsData as InterpretedResults);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmptyOrUndefined(resultsData, 'obj')) {
      setData(resultsData as InterpretedResults);
    }
  }, [resultsData]);

  const [show, setShow] = useState(false);
  const target = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (target.current && !target.current.contains(event.target as Node)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MainLayout>
      <Container fluid className="page pt-2 pt-lg-4 px-0">
        <Container className="page-container">
          <Card className="result-card p-4">
            <div>
              <h1 className="result-title">Mis resultados</h1>
              <p className="result-subtitle mt-4 mr-2">Explora tus resultados genéticos.
                Aquí encontrarás información detallada sobre diversos genes que pueden influir en tu salud y bienestar.
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img src={IMAGE_RESULTS_SRC}
                alt="explicacion_resultados_geneticos.png"
                className="d-none d-lg-block"
                width={"80%"}
              />
            </div>
            <img src={IMAGE_RESULTS_MOBILE_1_SRC}
              alt="explicacion_resultados_geneticos.png"
              className="d-block d-lg-none"
              width={"100%"}
            />
            <img src={IMAGE_RESULTS_MOBILE_2_SRC}
              alt="explicacion_resultados_geneticos.png"
              className="d-block d-lg-none"
              width={"100%"}
            />
          </Card>
          <Card className="result-card my-2 p-4">
            <span>
              <b>Pro Tip: </b>
              Recuerda que puedes acceder a la justificacion de cada alimento como este
              <Badge pill className="mx-2 badge-avoid point" ref={target} onClick={() => setShow(!show)}>
                Maní 🥜
              </Badge>
              haciendo click en él.
            </span>
            <Overlay target={target.current} show={show} placement='bottom'>
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Clickea las comidas de tus resultados para más información
                </Tooltip>
              )}
            </Overlay>

          </Card>
          <ResultsCategories data={data?.results} resultMealsRestrictions={resultMealsRestrictions} />
        </Container>
      </Container>
    </MainLayout>
  );
}

export default ResultPage;

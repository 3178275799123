import React, { useState } from "react";
import MynuBlueLogo from '../../assets/images/mynu-blue-logo.png';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import FormButton from "../../components/FormButton/FormButton";

const { Group, Label, Control, Text } = Form;

interface NewPasswordFormProps {
  handleClick( code: string, password: string): Promise<void>
  handleRecovery(email: string): Promise<void>
  email: string
}

function NewPasswordForm({ handleClick, handleRecovery, email }: NewPasswordFormProps) {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [inputPasswordType, setInputPasswordType] = useState('password');
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  const changeToShowPassword = () => {
    if (inputPasswordType === 'password') {
      setInputPasswordType('text');
    } else {
      setInputPasswordType('password');
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick(code, password);
  }

  const validatePassword = (password: string) => {
    const regexValidation = /(?=[A-Za-z0-9]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/;
    setIsInvalidPassword(!regexValidation.test(password));
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    validatePassword(value);
    setPassword(value);
  }

  const resendCode = async() => {
    handleRecovery(email)
  }

  return (
    <div className="form-container pt-2">
      <div className="text-center d-lg-none pb-3">
        <img src={MynuBlueLogo} className="mobile-logo" alt="" width={200}/>
      </div>
      <h1 className="form-title text-center mb-3">Ingresa código de verificación</h1>
      <p className="description fw-bold  text-center">Se envió código de verificación a tu correo</p>
      <Form onSubmit={handleSubmit} className="mt-4">
        <Group className="mb-2" controlId='verificationCode'>
          <Label className="mb-1">Código:</Label>
        <Control
          required 
          name="code"
          type="text"
          placeholder="Ej: 123456"
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
        </Group>
        <Label htmlFor="password-recovery-new-password">
          Nueva contraseña:
        </Label>
        <InputGroup className="mb-2">
          <Control
            id="password-recovery-new-password"
            name="password"
            required
            type={inputPasswordType}
            value={password}
            onChange={handleChangePassword}
            isInvalid={isInvalidPassword}
          />
          <InputGroup.Text
            onClick={changeToShowPassword}
            className="password-icon"
            role="button"
          >
            {
              inputPasswordType === 'password' ?
                <EyeFill size={20}/>
              :
                <EyeSlashFill size={20}/>
            }
          </InputGroup.Text>
          <Text className="text-muted form-assistive-text">
            Mínimo 8 caracteres, números, mayúsculas y minúsculas.
          </Text>
        </InputGroup>
        <div className="text-center">
          <FormButton text="Enviar" isDisabled={!code || isInvalidPassword} />
        </div>
      </Form>
      <div className="text-center">
        <p className="note mt-3 mb-2">
          En caso no recibas un mensaje con el código, por favor verificar el correo ingresado y tratar nuevamente.
        </p>
        <Button variant="link" className="resendCodeButton" onClick={resendCode}>
          Reenviar código
        </Button>
      </div>
    </div>
  )
}

export default NewPasswordForm;

import { Form } from 'react-bootstrap';

import MynuTooltip from "../../../../components/Tooltip/Tooltip";
import { BuyKitFormProps } from "../../BuyKitPage";

import { comunas_regiones } from "../../comunas-regiones";

const { Group, Label, Control, Select } = Form;

const DEFAULT_REGION_OPTION = 'Región Metropolitana de Santiago';

export default function DeliveryStep(props: BuyKitFormProps) {

  const {
    formData,
    setFormData,
    attributeValidations,
    setAttributeValidations
  } = props;

  const provinceOptions = comunas_regiones?.regiones.find(r => r.region === DEFAULT_REGION_OPTION)?.comunas ?? [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newValues = {
      ...formData,
      [name]: value,
    }
    setFormData(newValues);

    const newValidation = {
      ...attributeValidations,
      [name]: Boolean(!value)
    }

    if (name === 'address') {
      newValidation.address = Boolean(!value)
    }

    setAttributeValidations(newValidation);
  };

  const handleSelectorChange = (paramName: string, value: string) => {
    const newValues = {
      ...formData,
      [paramName]: value,
    }

    const newValidation = {
      ...attributeValidations,
      [paramName]: Boolean(!value)
    }

    if (paramName === 'province') {
      newValidation.province = Boolean(!value)
    }

    setAttributeValidations(newValidation);
    setFormData(newValues);
  }


  return (
    <div className="delivery">
      <Group className="my-2" controlId={'buy-kit-form-city'}>
        <Label className="buy-kit-form-label mb-1">Ciudad</Label>
        <MynuTooltip description="Por el momento solo se entregan kits en Santiago">
          <Control
            //required
            disabled
            name={'city'}
            type={'text'}
            placeholder={'Santiago'}
          //value={formData.city}
          //onChange={handleChange}
          />
        </MynuTooltip>
      </Group>
      <Group className="my-2" controlId={'buy-kit-form-reg'}>
        <Label className="buy-kit-form-label mb-1">Región</Label>
        <MynuTooltip description="Por el momento solo se entregan kits en la Región Metropolitana">
          <Control
            //required
            disabled
            name={'region'}
            type={'text'}
            placeholder={'Región Metropolitana de Santiago'}
          //value={formData.region}
          //onChange={handleChange}
          />
        </MynuTooltip>
      </Group>

      <Group className="my-2" controlId={'buy-kit-form-address'} key={'address'}>
        <Label className="buy-kit-form-label mb-1">Dirección</Label>
        <Control
          required
          name={'address'}
          type={'text'}
          placeholder={'Av. Américo Vespucio 123'}
          value={formData.address}
          onChange={handleChange}
          isInvalid={attributeValidations.address}
          className={formData.address && !attributeValidations.address ? 'is-valid' : ''}
        />
        <Control.Feedback type="invalid">Debes ingresar una dirección</Control.Feedback>
      </Group>

      <Group className="my-2" controlId={'buy-kit-form-address2'} key={'address2'}>
        <Label className="buy-kit-form-label mb-1">Info adicional (ej: depto/oficina)</Label>
        <Control
          name={'address2'}
          type={'text'}
          placeholder={'Oficina 204'}
          value={formData.address2}
          onChange={handleChange}
        />
      </Group>

      <Group className="mb-2" controlId={'buy-kit-form-province'}>
        <Label className="buy-kit-form-label mb-1">Comuna</Label>
        <Select
          required
          name={'province'}
          value={formData.province}
          onChange={(event) => handleSelectorChange('province', event.target.value)}
          isInvalid={attributeValidations.province}
          className={formData.province && !attributeValidations.province ? 'is-valid' : ''}
        >
          {
            ['', ...provinceOptions]?.map((c, ix) => {
              return <option key={ix} value={c}>{c}</option>
            })
          }
        </Select>
        <Control.Feedback type="invalid">Por favor selecciona una comuna</Control.Feedback>
      </Group>
    </div>
  )
}
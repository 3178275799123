import { useNavigate } from "react-router-dom";
import WhiteLogo from "../../../assets/images/mynu-white-logo.png";
import { ArrowLeft } from "react-bootstrap-icons";
import { kitData } from "../constants";

export default function Header() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className="header">
      <div className="back-button-container" onClick={goBack}>
        <ArrowLeft className="arrow-back" />
        <div className="header-text mobile-hidden">Compra tu {kitData.kitName}🧬 aquí!</div>
      </div>
      <div className="logo-container">
        <img src={WhiteLogo} alt="Mynu White Logo" className="logo" />
      </div>
    </div>
  )
}
import {FC} from 'react';
import { Card } from 'react-bootstrap';

type QuestionnaireCardProps = {
  children: any;
}

export const QuestionnaireCard: FC<QuestionnaireCardProps> = (
  {children}: QuestionnaireCardProps) =>  {
    return (
      <Card className="p-4 mb-2 home-card">
        {children}  
      </Card>
    )
  }
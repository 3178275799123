import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Modal } from 'react-bootstrap';

// state & context
import { loadQuestionnaire, loadQuestionnaireOptions, loadUserData } from '../../store/loader';
import { useStateStore } from '../../store/store';
import { useCurrentUser } from '../../components/AuthContext';

// services
import {
  saveQuestionnaire,
  getQuestionnaire,
} from '../../services/questionary/questionary-service';

// models & types
import {
  FormPersonalData,
  FormDailyRoutine,
  FormNutrition,
} from '../../models';
import { QuestionnaireForms, formDomainAdapter } from './FormDomainAdapter';

// my components
import QuestionaryFormPersonalInformation from './QuestionaryFormPersonalInformation';
import QuestionaryFormDailyRoutines from './QuestionaryFormDailyRoutines';
import QuestionaryFormNutrition from './QuestionaryFormNutrition';
import SuccessMessage from '../../components/SuccessMessage/SuccessMessage';
import BackdropComponent from './components/BackdropComponent';

// utils
import { validSubscription } from '../../utils';
import { AppState } from '../../store/interfaces';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

const saveFormData = async (
  formPersonalInfoToSave: FormPersonalData,
  formDailyRoutineToSave: FormDailyRoutine,
  formNutritionToSave: FormNutrition,
  store: AppState
) => {
  const userToken = localStorage.getItem('userToken');

  if (!userToken) throw new Error('No user token found!');
    
  const questionnaireForms = {
    personalData: {
      ...formPersonalInfoToSave,
      medicalConditionIds: formPersonalInfoToSave.medicalConditionIds.filter(c => c !== 'none'),
      allergyOrIntoleranceIds: formPersonalInfoToSave.allergyOrIntoleranceIds.filter(c => c !== 'none'),
      medicationIds: formPersonalInfoToSave.medicationIds.filter(c => c !== 'none')
    },
    dailyRoutine: formDailyRoutineToSave,
    nutrition: {
      ...formNutritionToSave,
      foodSupplementIds: formNutritionToSave.foodSupplementIds.filter(c => c !== 'none')
    }
  };

  const { hasResults, setQuestionnaireData } = store;
  const questionnaireData = formDomainAdapter.formToDomain(questionnaireForms);

  await saveQuestionnaire(questionnaireData, userToken, hasResults());

  setQuestionnaireData(questionnaireData);
};

const getFormsFromData = async (store: AppState): Promise<QuestionnaireForms> => {
  const userToken = localStorage.getItem('userToken');
  if (!store) throw new Error('No store found!');
  if (!userToken) throw new Error('No user token found!');
  store.setIsLoadingQuestionnaire(true);
  const questionnaireData = await getQuestionnaire(userToken);
  store.setIsLoadingQuestionnaire(false);

  return formDomainAdapter.domainToForm(questionnaireData);
};

function Questionary() {
  const navigate = useNavigate();
  const store = useStateStore();
  const currentUser = useCurrentUser();

  const [error, setError] = useState(null);
  const [errorSaveData, setErrorSaveData] = useState<boolean>(false);
  const [finishedQuestionnaire, setFinishedQuestionnaire] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [showingFormPersonalInformation, setShowingFormPersonalInformation] = useState(true);
  const [showingFormDailyRoutines, setShowingFormDailyRoutines] = useState(false);
  const [showingFormNutrition, setShowingFormNutrition] = useState(false);

  const [isUploadingQuestionnaire, setIsUploadingQuestionnaire] = useState(false);

  const {
    questionnaireData,
    isLoadingQuestionnaire,
    questionnaireOptions,
    isLoadingQuestionnaireOptions,
    setIsLoadingQuestionnaire,
  } = store;

  const [formPersonalInfo, setFormPersonalInfo] = useState<FormPersonalData>({
    genderId: undefined,
    birthDate: undefined,
    weightKg: undefined,
    heightCm: undefined,
    medicalConditionIds: [],
    otherMedicalConditionsText: undefined,
    allergyOrIntoleranceIds: [],
    otherAllergiesOrIntolerancesText: undefined,
    medicationIds: [],
    smokes: undefined,
    alcoholConsumptionLevelId: undefined,
    goalId: undefined,
    wellnessImprovementGoalId: undefined,
    hasFollowedDiet: undefined,
    experienceFollowingDietsText: undefined,
    experienceTryingToLooseWeightText: undefined,
    weightGoalKg: undefined,
    hasTriedToGainWeight: undefined,
    followingDiet: undefined,
    gainMuscularWeightFatTimePriorityId: undefined,
    gainMuscularWeightTrainingTimeInMonths: undefined,
    gainMuscularWeightApetiteLevelId: undefined
  });

  const [formDailyRoutine, setFormDailyRoutine] = useState<FormDailyRoutine>({
    sleepHoursId: undefined,
    sleepDescriptionIds: [],
    engagesInSport: undefined,
    sportsData: [],
    dailyActivityLevelId: undefined,
    meanOfTransportId: undefined
  });

  const [formNutrition, setFormNutrition] = useState<FormNutrition>({
    mealsPerDayIds: [],
    dietRestrictionTypeIds: [],
    intermitentFastingHours: undefined,
    intermitentFastingFirstMealHour: undefined,
    animalProteinPreferenceIds: [],
    vegetalProteinPreferenceIds: [],
    legumePreferenceIds: [],
    carbPreferenceIds: [],
    fatPreferenceIds: [],
    lactosePreferenceIds: [],
    fruitPreferenceIds: [],
    vegetablePreferenceIds: [],
    foodSupplementIds: [],
    vitaminSupplementsText: undefined,
    mineralSupplementsText: undefined,
    otherSupplementsText: undefined,
    dailyWaterConsumptionLevelId: undefined,
    sugaryDrinkFrequencyId: undefined,
    nonSugarDrinkFrequenceId: undefined,
    generalSweetsFrequencyId: undefined,
    generalFastFoodFrequencyId: undefined,
    apetiteFeelingId: undefined
  });

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    const loadInfo = async () => {
      if (userToken && !questionnaireData && currentUser) {
        await loadQuestionnaire(store, currentUser, userToken);
        await loadQuestionnaireOptions(store, currentUser, userToken);
      }
    }

    loadInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkSubscription();

    getFormsFromData(store).then(forms => {
      const {
        personalData,
        dailyRoutine,
        nutrition
      } = forms;

      setFormPersonalInfo(personalData);
      setFormDailyRoutine(dailyRoutine);
      setFormNutrition(nutrition);
    }).catch((err: any) => {
      console.log(err.message);
    })
      .finally(() => setRefreshData(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  const checkSubscription = async () => {
    if (currentUser && currentUser?.subscription) {
      if (currentUser?.subscription) {
        if (!validSubscription(currentUser?.subscription)) {
          navigate('/');
        }
      }
    }
  }

  const updatePersonalInformationData = useCallback((form: FormPersonalData) => {
    setFormPersonalInfo(form);
  }, []);

  const updateDailyRoutineData = useCallback((form: FormDailyRoutine) => {
    setFormDailyRoutine(form);
  }, []);

  const updateNutritionDataAndEndQuestionnaire = useCallback(async (form: FormNutrition) => {
    setIsLoadingQuestionnaire(true);
    setIsUploadingQuestionnaire(true);
    try {
      setFormNutrition(form);
      await saveFormData(formPersonalInfo, formDailyRoutine, form, store);
      setFinishedQuestionnaire(true);
      showFormPersonalInfo();
    } catch (error) {
      console.log('Ha ocurrido un error');
      setErrorSaveData(true);
    } finally {
      setIsLoadingQuestionnaire(false);
      setIsUploadingQuestionnaire(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPersonalInfo, formDailyRoutine]);

  const showFormPersonalInfo = () => {
    setShowingFormDailyRoutines(false);
    setShowingFormPersonalInformation(true);
    setShowingFormNutrition(false);
  };

  const showFormDailyRoutines = () => {
    setShowingFormDailyRoutines(true);
    setShowingFormPersonalInformation(false);
    setShowingFormNutrition(false);
  };

  const showFormNutrition = () => {
    setShowingFormDailyRoutines(false);
    setShowingFormPersonalInformation(false);
    setShowingFormNutrition(true);
  };

  const backToFormPersonalInformation = async (form: FormDailyRoutine) => {
    setFormDailyRoutine(form);

    showFormPersonalInfo();
  };

  const backToFormDailyRoutines = async (form: FormNutrition) => {
    setErrorSaveData(false);
    setFormNutrition(form);

    showFormDailyRoutines();
  };

  const closeQuestionaryPersonalData = (async (form: FormPersonalData, save: boolean) => {
    if (!errorSaveData) {
      await loadUserData(store);
      navigate('/');
    }
  });

  const closeQuestionaryDailyData = (async (form: FormDailyRoutine, save: boolean) => {
    if (!errorSaveData) {
      await loadUserData(store);
      navigate('/');
    }
  });

  const closeQuestionaryNutritionData = (async (form: FormNutrition, save: boolean) => {
    if (!errorSaveData) {
      await loadUserData(store);
      navigate('/');
    }
  });

  if (finishedQuestionnaire) {
    return (
      <Container className='w-100 m-auto'>
        <SuccessMessage
          text="Has completado el cuestionario!"
          description="Gracias por tomarte el tiempo de completar tu perfilamiento.
          Ahora estamos un paso más cerca para brindarte una dieta personalizada que se adapta
          a tus necesidades y objetivos."
          to="/"
          textLink="Ir a Inicio" />
      </Container>
    )
  }

  else if (errorSaveData) {
    return (
      <Container className="w-100 m-auto">
        <ErrorMessage
          text="Oops, ha ocurrido un error!"
          description="Ha ocurrido un error al guardar tus datos, por favor intenta de nuevo más tarde."
          to="/"
          textLink="Ir a Inicio" />
      </Container>
    )
  }

  return (
    <>
      <BackdropComponent
        visible={isLoadingQuestionnaire || isLoadingQuestionnaireOptions || isUploadingQuestionnaire}
        displayText={`${!isUploadingQuestionnaire ? 'Cargando' : 'Subiendo'} Cuestionario`}
      />
      {questionnaireOptions && <Container fluid className="page pt-2 pt-lg-4 px-0">
        <Container className="page-container">
          {showingFormPersonalInformation && (
            <QuestionaryFormPersonalInformation
              options={questionnaireOptions}
              updateForm={(form) => updatePersonalInformationData(form)}
              goToNextSection={showFormDailyRoutines}
              currentForm={formPersonalInfo}
              goBack={(form, save) => closeQuestionaryPersonalData(form, save)}
              close={(form, save) => closeQuestionaryPersonalData(form, save)}
            />
          )}
          {showingFormDailyRoutines && (
            <QuestionaryFormDailyRoutines
              options={questionnaireOptions}
              updateForm={(form) => updateDailyRoutineData(form)}
              gotoNextSection={showFormNutrition}
              currentForm={formDailyRoutine}
              goBack={(form) => backToFormPersonalInformation(form)}
              close={(form, save) => closeQuestionaryDailyData(form, save)}
            />
          )}
          {showingFormNutrition && (
            <QuestionaryFormNutrition
              options={questionnaireOptions}
              updateFormAndGoToNextSection={async (form) => await updateNutritionDataAndEndQuestionnaire(form)}
              currentForm={formNutrition}
              goBack={(form) => backToFormDailyRoutines(form)}
              close={(form, save) => closeQuestionaryNutritionData(form, save)}
            />
          )}
        </Container>
        <Modal show={error !== null} onHide={() => setError(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error}
          </Modal.Body>
        </Modal>
      </Container>}
    </>
  );
}

export default Questionary;
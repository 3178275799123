import { useState } from "react";
import Form from 'react-bootstrap/Form';

import { BuyKitFormProps } from "../../BuyKitPage";

export default function InfoStep(props: BuyKitFormProps) {
  const {
    formData,
    setFormData,
    attributeValidations,
    setAttributeValidations
  } = props;

  const [emailConfirmation, setEmailConfirmation] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    if (name === 'phoneNumber' && (!/^\+?[0-9]*$/.test(value) || value.length > 12)) {
      return;
    }

    const newValues = {
      ...formData,
      [name]: name === 'acceptedConditions' ? checked : value,
    }
    setFormData(newValues);

    const newValidation = {
      ...attributeValidations,
      [name]: Boolean(!value)
    }

    if (name === 'phoneNumber' && value.length < 9) {
      newValidation.phoneNumber = true
    }

    if (name === 'email') {
      validateEmail(value);
      return;
    }

    setAttributeValidations(newValidation);
  };

  const validateEmail = (value: string) => {
    const regexValidation = /\S+@\S+\.\S+/;
    const newValidation = {
      ...attributeValidations,
      email: !regexValidation.test(value),
      emailConfirmation: emailConfirmation !== value || !regexValidation.test(value)
    }
    setAttributeValidations(newValidation)
  }

  const handleEmailConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regexValidation = /\S+@\S+\.\S+/;
    const value = event.target.value;
    setEmailConfirmation(value);
    const newValidation = {
      ...attributeValidations,
      emailConfirmation: formData.email !== value || !regexValidation.test(value)
    }
    setAttributeValidations(newValidation)
  }

  return (
    <>
      <Form.Group className="my-2" controlId={'buy-kit-form-firstName'}>
        <Form.Label className="buy-kit-form-label mb-1">Nombres</Form.Label>
        <Form.Control
          required
          name={'firstName'}
          type={'text'}
          placeholder={'José Manuel'}
          value={formData.firstName}
          onChange={handleChange}
          isInvalid={attributeValidations.firstName}
          className={formData.firstName && !attributeValidations.firstName ? 'is-valid' : ''}
        />
        <Form.Control.Feedback type="invalid">Debes ingresar tu nombre</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-lastName'}>
        <Form.Label className="buy-kit-form-label mb-1">Apellidos</Form.Label>
        <Form.Control
          required
          name={'lastName'}
          type={'text'}
          placeholder={'Carrera Pérez'}
          value={formData.lastName}
          onChange={handleChange}
          isInvalid={attributeValidations.lastName}
          className={formData.lastName && !attributeValidations.lastName ? 'is-valid' : ''}
        />
        <Form.Control.Feedback type="invalid">Debes ingresar tus apellidos</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-email'}>
        <Form.Label className="buy-kit-form-label mb-1">Correo Electrónico</Form.Label>
        <Form.Control
          required
          name={'email'}
          type={'email'}
          placeholder={"jose@gmail.com"}
          value={formData.email}
          onChange={handleChange}
          isInvalid={attributeValidations.email}
          className={formData.email && !attributeValidations.email ? 'is-valid' : ''}
        />
        <Form.Control.Feedback type="invalid">Debes ingresar un email válido</Form.Control.Feedback>
      </Form.Group>


      <Form.Group className="my-2" controlId={'buy-kit-form-email-validation'}>
        <Form.Label className="buy-kit-form-label mb-1">Confirma tu correo electrónico</Form.Label>
        <Form.Control
          required
          type={'email'}
          placeholder={"jose@gmail.com"}
          value={emailConfirmation}
          onChange={handleEmailConfirmationChange}
          isInvalid={attributeValidations.emailConfirmation}
          className={emailConfirmation && !attributeValidations.emailConfirmation ? 'is-valid' : ''}
        />
        <Form.Control.Feedback type="invalid">Los emails deben coincidir</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-phoneNumber'}>
        <Form.Label className="buy-kit-form-label mb-1">Teléfono</Form.Label>
        <Form.Control
          required
          name={'phoneNumber'}
          type={'text'}
          placeholder={'+56912345678'}
          value={formData.phoneNumber}
          onChange={handleChange}
          isInvalid={attributeValidations.phoneNumber}
          className={formData.phoneNumber && !attributeValidations.phoneNumber ? 'is-valid' : ''}
        />
        <Form.Control.Feedback type="invalid">Debes ingresar un teléfono válido</Form.Control.Feedback>  
      </Form.Group>
    </>
  )
}
import { Container } from "react-bootstrap"
import BackButton from "../components/BackButton/BackButtton"
import MynuBlueLogo from "../assets/images/mynu-blue-logo.png";

export const NotAuthorizedPage = () => {
  return (
    <Container>
      <div className="text-center d-lg-none p-3">
          <img src={MynuBlueLogo} className="mobile-logo" alt="" width={100}/>
      </div>
      <div className="mt-3 p-3">
        <div className="mb-2">
          <h1>Ups!</h1>
        </div>
        <div className="mb-2">
          <p>No estás autorizado para ver este contenido!</p>
            <BackButton text="Volver atrás" />
          <div className="mb-2 py-3">
          </div>
        </div>
      </div>
    </Container>
  )
}
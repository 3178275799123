import { FC, useState, useEffect, useCallback } from 'react';
import {Form} from 'react-bootstrap';
import { QuestionnaireCard } from './QuestionnaireCard';

const { Control, Group, Label } = Form;

type DateComponentProps = {
  name: string;
  question: string;
  required: boolean;
  initialDate?: string;
  updateDate: (selectedDate: string) => void;
  isValid: (selectedIds: string[]) => boolean
}

const getMaxDate = (): string => {
  const maximumDate = new Date();
  maximumDate.setFullYear(maximumDate.getFullYear() - 2);
  return (maximumDate).toISOString().split('T')[0];
}

const DateComponent: FC<DateComponentProps> = (props: DateComponentProps) => {
  const {
    name,
    question,
    required,
    initialDate,
    updateDate
  } = props;
  const [selectedDate, setSelectedDate] = useState('');
  
  useEffect(() => {
    if (initialDate) setSelectedDate(initialDate);
  }, [initialDate]);
  
  const triggerDateUpdate = useCallback( (newDate:string) => {
    setSelectedDate(newDate);
    updateDate(newDate);
  }, [updateDate]);
  
  return (<QuestionnaireCard>
    <Group controlId={name+'Date'} key={name+'Date'} className='mb-1'>
      <Label className="form-label">{question}</Label>
      <Control
        required={required}
        name={name}
        type='date'
        placeholder='DD/MM/YY'
        value={selectedDate}
        max={getMaxDate()}
        onChange={(event) => {
          const newVal = event.target.value;
          triggerDateUpdate(newVal);
        }}
      />
    </Group>
  </QuestionnaireCard>)
}

export default DateComponent;
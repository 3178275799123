import { Plans } from '../models'

const subscriptionTypeMap: Record<string, string>  = {
    premium: 'Premium',
    freemium: 'Freemium',
  };
  
  export const getSubscriptionType = (unformattedType: string): string => {
    return subscriptionTypeMap[unformattedType] || '';
  };

  export const limitChatGPTUses = 7;
  
  export const plans: Plans = {
    'premium': {
        badge: 'Plan Premium',
        title: 'Suscripción a Mynu',
        identifiers: {
            monthly: 1,
            anual: 2,
        },
        pricing: {
            monthly: [
                {
                    price: '$4.500',
                    description: 'SUSCRIPCIÓN MENSUAL',
                }
            ],
            anual: [
                {
                    price: '$42.500',
                    description: 'SUSCRIPCIÓN ANUAL',
                }
            ],
        },
        details: [
            {
                included: true,
                description: 'Acceso ilimitado a dietas personalizadas',
            },
            {
                included: true,
                description: 'Acceso ilimitado a tu coach Numy',
            },
            {
                included: true,
                description: 'Acceso a teleconsultas con nuestras nutricionistas',
            }
        ],
    },
    'kit': {
        badge: 'Pago Único',
        title: 'Kit de ADN',
        identifiers: {
            monthly: 7,
            anual: 7,
        },
        pricing: {
            monthly: [
                {
                    price: '$99.000',
                    description: 'KIT',
                },
            ],
            anual: [
                {
                    price: '$99.000',
                    description: 'KIT',
                },
            ],
        },
        details: [
            {
                included: true,
                description: 'Incluye Kit de ADN + 3 meses de suscripción a Mynu',
            },
            {
                included: true,
                description: '3 meses de acceso ilimitado a dietas personalizadas',
            },
            {
                included: true,
                description: '3 meses de acceso ilimitado a tu coach Numy',
            },
            {
                included: true,
                description: '3 meses de acceso a teleconsultas con nuestras nutricionistas',
            }
        ],
    },
}
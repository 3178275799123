import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import FormButton from '../../components/FormButton/FormButton';
import { NewUser } from '../../models';
import { FormInput } from '../../components/Signup/FormInput';
import './SignupForm.scss';

const { Group, Label, Control, Text, Check } = Form;

interface Props {
  submitUserData( userData: NewUser ): Promise<void>; 
  showAlert( variant: string, text: string ): void;
}


const inputs: FormInput[] = [
  {
    id: 'givenName',
    label: 'Nombres',
    type: 'text',
    placeholder: 'Ej: “Luisa Angélica”',
    feedback: 'Debes ingresar tu nombre'
  },
  {
    id: 'familyName',
    label: 'Apellidos',
    type: 'text',
    placeholder: 'Ej: “Reyes Ramirez”',
    feedback: 'Debes ingresar tu apellido'
  },
  {
    id: 'phoneNumber',
    label: 'Celular (Incluye código de país)',
    type: 'text',
    placeholder: 'Ej: “+56922334455”',
    feedback: 'Debes ingresar tu número de célular'
  },
  {
    id: 'email',
    label: 'Correo electrónico',
    type: 'email',
    placeholder: 'Ej: “Luisa@mail.cl”',
    feedback: 'Formato de correo inválido'
  },
]

function SignupForm({ submitUserData, showAlert }: Props ) {
  const [inputPasswordType, setInputPasswordType] = useState('password');
  const [formData, setFormData] = useState({
    givenName: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    password: '',
    acceptedConditions: false,
  })
  const [ passwordConfirmation, setPasswordConfirmation ] = useState('');
  const [attributeValidations, setAttributeValidations] = useState({
    givenName: false,
    familyName: false,
    phoneNumber: false,
    email: false,
    password: false,
    passwordConfirmation: false,
    acceptedConditions: false,
  })

  const changeToShowPassword = () => {
    if (inputPasswordType === 'password') {
      setInputPasswordType('text');
    } else {
      setInputPasswordType('password');
    }
  };



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    if (name === 'phoneNumber' && (!/^\+?[0-9]*$/.test(value) || value.length > 15)){
      return;
    }

    const newValues = {
      ...formData,
      [name] : name === 'acceptedConditions' ? checked : value,
    }
    setFormData(newValues);

    if (name === 'password') {
      validatePassword(value);
      return
    }

    const newValidation = {
      ...attributeValidations,
      [name] : Boolean(!value)
    }

    if (name === 'acceptedConditions') {
      newValidation.acceptedConditions = !checked;
    }

    if (name === 'phoneNumber' && value.length < 9){
      newValidation.phoneNumber = true
    }

    if (name === 'email'){
      newValidation.email = Boolean(!/\S+@\S+\.\S+/.test(formData.email));

    }

    setAttributeValidations(newValidation);
  };

  const handlePasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regexValidation = /(?=[A-Za-z0-9]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/;
    const value = event.target.value;
    setPasswordConfirmation(value);
    const newValidation = {
      ...attributeValidations,
      passwordConfirmation : formData.password !== value || !regexValidation.test(value)
    }
    setAttributeValidations(newValidation)
  }

  const validatePassword = (password: string) => {
    const regexValidation = /(?=[A-Za-z0-9]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/;
    const newValidation = {
      ...attributeValidations,
      password: !regexValidation.test(password),
      passwordConfirmation : password !== passwordConfirmation || !regexValidation.test(password)
    }
    setAttributeValidations(newValidation);
  }

  const validateAttributes = () => {
    const newValidation = {
      ...attributeValidations
    };
    newValidation.givenName = Boolean(!formData.givenName);
    newValidation.familyName = Boolean(!formData.familyName);
    newValidation.phoneNumber = Boolean(!formData.phoneNumber);
    newValidation.email = Boolean(!/\S+@\S+\.\S+/.test(formData.email));
    newValidation.acceptedConditions = Boolean(!formData.acceptedConditions);
    if (!formData.password) {
      newValidation.password = true;
    }
    if (!passwordConfirmation) {
      newValidation.passwordConfirmation = true;
    }
    
    setAttributeValidations(newValidation);
    return newValidation;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    
    const form = event.currentTarget;
    const newValidation = validateAttributes();

    //Valida si algun atributo del formulario es inválido
    if (Object.values(newValidation).some((validation) => validation === true)){
      showAlert('warning', 'Debes completar todos tus datos')
    } else {
      if (form.checkValidity() === true) {
        localStorage.setItem('userEmail', formData.email);
        const { acceptedConditions, ...userData } = formData;
        submitUserData(userData);
      }
    };
  }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {inputs.map((input) => {
        return (
          <Group className="mb-2" controlId={`signup-form-${input.id}`} key={input.id}>
            <Label className="signup-form-label mb-1">{input.label}</Label>
            
              <Control
                required 
                name={input.id}
                type={input.type}
                placeholder={input.placeholder}
                value={formData[input.id]}
                onChange={handleChange}
                isInvalid={attributeValidations[input.id]}
                className={formData[input.id] && !attributeValidations[input.id] ? 'is-valid' : ''}
              />
              
            {input.text && (
              <Text className="text-muted form-assistive-text">
                {input.text}
              </Text>
            )}
            <Control.Feedback type="invalid">
              {input.feedback}
            </Control.Feedback>
          </Group>
        )
      })}
      <Label htmlFor="signup-form-password" className='signup-form-label'>
        Contraseña
      </Label>
      <InputGroup className="mb-2" hasValidation={false}>
        <Control
          id="signup-form-password"
          name="password"
          type={inputPasswordType}
          value={formData.password}
          onChange={handleChange}
          isInvalid={attributeValidations.password}
          required
          placeholder='••••••••••••••••'
          className={formData.password && !attributeValidations.password ? 'is-valid' : ''}
        />
        <InputGroup.Text
          onClick={changeToShowPassword}
          className="password-icon"
          role="button"
        >
          {
            inputPasswordType === 'password' ?
              <EyeFill size={20}/>
            :
              <EyeSlashFill size={20}/>
          }
        </InputGroup.Text>
        <Text className="text-muted form-assistive-text">
          Mínimo 8 caracteres, números, mayúsculas y minúsculas.
        </Text>
      </InputGroup>
      <Label htmlFor="signup-form-confirmation-password" className='signup-form-label' >
        Vuelve a escribir tu contraseña
      </Label>
      <InputGroup className="mb-2">
        <Control
          id="signup-form-confirmation-password"
          type={inputPasswordType}
          value={passwordConfirmation}
          onChange={handlePasswordConfirmationChange}
          isInvalid={attributeValidations.passwordConfirmation}
          required
          placeholder='••••••••••••••••'
          className={passwordConfirmation && !attributeValidations.passwordConfirmation ? 'is-valid' : ''}
        />
        <InputGroup.Text onClick={changeToShowPassword} className="password-icon" role="button">
          {
            inputPasswordType === 'password' ?
              <EyeFill size={20}/>
            :
              <EyeSlashFill size={20}/>
          }
        </InputGroup.Text>
        <Text className="text-muted form-assistive-text">
          Mínimo 8 caracteres, números, mayúsculas y minúsculas.
        </Text>
      </InputGroup>
      <Group className="mb-2 form-assistive-text" controlId="signup-formTerms">
        <Check
          required
          type="checkbox"
          name="acceptedConditions"
          label={<>Al registrarme acepto los <a href='https://mynu.ai/terminos/' target='_blank' rel='noreferrer'>términos y condiciones</a></>}
          isInvalid={attributeValidations.acceptedConditions}
          onChange={handleChange}
        />
      </Group>
      <div className="text-center">
        <FormButton text="Registrarse" />
      </div>
    </Form>
  );
}

export default SignupForm;
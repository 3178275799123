import { useEffect, useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import FadeLoader from "react-spinners/FadeLoader";

// state & context
import { useStateStore } from '../../../../store/store';

// models & serices
import { Meal } from '../../../../models/diet-model';
import { getMealOptions, changeMeal, requestNewDiet } from '../../../../services/diet';
import { Subscription, User } from '../../../../models';
import { getSubscription } from '../../../../services/subscription';
import { changeDailyMenu } from '../../../../services/diet';

// my components
import MealCard from './MealCard';
import MealOptionsModal from '../MealOptionsModal';
import SubscriptionModal from '../../../../components/SubscriptionModal/SubscriptionModal';
import SubscriptionPlanModal from '../../../../components/SubscriptionPlanModal/SubscriptionPlanModal';

// utils & constants
import { validSubscription } from '../../../../utils';
import { ArrowRepeat } from 'react-bootstrap-icons';
import './DietAccordion.scss';


const mealOrder = ['Desayuno', 'Media mañana (snack)', 'Almuerzo', 'Media tarde (snack)', 'Cena'];

type DietAccordionProps = {
  days: string[],
  diet: Meal[][],
  loadDiet: () => Promise<void>,
  currentUser: User | null,
  openAccordionDay: string,
  setOpenDietAccordionDay: (day: string) => void,
  setNotificationText: (message: string) => void,
}

function DietAccordion(props: DietAccordionProps) {
  const store = useStateStore();
  const { dietInformation } = store;

  const {
    days,
    loadDiet,
    currentUser,
    openAccordionDay,
    setOpenDietAccordionDay,
    setNotificationText,
  } = props;

  const [isLoadingDay, setIsLoadingDay] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [mealOptions, setMealOptions] = useState<string[][]>([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [showSubscriptionModal, setSubscriptionModal] = useState(false);
  const [showSubscriptionPlanModal, setShowSubscriptionPlanModal] = useState(false);

  const [sortedDiet, setSortedDiet] = useState<Meal[][]>([]);

  const changeMenuByDay = async (day: string) => {
    setIsLoadingDay(true);
    const userToken = localStorage.getItem('userToken');
    let subscription: Subscription | undefined = undefined;
    if (userToken) {
      subscription = await getSubscription(userToken) as Subscription;
    }

    if (!validSubscription(subscription)) {
      setSubscriptionModal(true)
    }
    else {
      try {
        const userToken = localStorage.getItem('userToken');
        if (userToken) {
          setOpenDietAccordionDay('dailyMeal-' + day)
          await changeDailyMenu(day, userToken);
          await loadDiet();
          setNotificationText(`Tu dieta del día ${day} se ha actualizado exitosamente`);
        }
      } catch (error) {
        setNotificationText('Error al actualizar tu dieta. Vuelve a intentarlo');
      }
    }
    setIsLoadingDay(false);
  }

  const requestMealOptions = async (day: string, menuName: string) => {
    setIsLoadingDay(true);
    const userToken = localStorage.getItem('userToken');

    if (currentUser?.subscription && !validSubscription(currentUser?.subscription)) {
      setSubscriptionModal(true)
    }
    else {
      setSelectedMeal(menuName);
      setSelectedDay(day);

      if (userToken) {
        getMealOptions(day, menuName, userToken)
          .then((response) => {
            onOpenModal(response);
          })
          .catch((error) => {
            setNotificationText('Error al actualizar tu dieta. Vuelve a intentarlo');
          });
      }
    }
    setIsLoadingDay(false);
  }

  const onOpenModal = (options: string[][]) => {
    setMealOptions(options);
    if (options.length > 0) {
      setShowModal(true);
    }
  }

  const onCloseModal = () => {
    setSelectedMeal('');
    setShowModal(false);
    setMealOptions([]);
  }

  const selectMealOption = async (
    day: string,
    menuName: string,
    newMenu: string[]
  ) => {
    setShowModal(false);
    const userToken = localStorage.getItem('userToken');

    if (userToken) {
      changeMeal(day, menuName, newMenu, userToken)
        .then(() => {
          loadDiet();
          setNotificationText(`Tu ${menuName} del día ${day} se ha actualizado exitosamente`);
        })
        .catch((error) => {
          setNotificationText('Error al actualizar tu dieta. Vuelve a intentarlo');
        })
        .finally(() => setSelectedMeal(''))
    }
  }

  const toggleSubscriptionPlan = () => {
    setShowSubscriptionPlanModal(!showSubscriptionPlanModal)
  }

  useEffect(() => {
    if (dietInformation.length) {
      const sorted = dietInformation.map((day) => {
        return day.sort((a, b) => mealOrder.indexOf(a.name) - mealOrder.indexOf(b.name))
      })
      setSortedDiet(sorted)
    }
  }, [dietInformation])

  useEffect(() => {
    if (openAccordionDay.length > 0) {
      window.scrollTo(0, 200)
    }
  }, [openAccordionDay])

  const handleAccordionClick = (attr: string) => {
    setOpenDietAccordionDay(openAccordionDay !== attr ? attr : '');
  }

  const reloadWeeklyDiet = async () => {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      requestNewDiet(userToken).then(async (status) => {
        if (status === 200) {
          await loadDiet()
        } else {
          setNotificationText('No fue posible actualizar tu dieta, por favor intenta mas tarde.')
        }
      }).catch(error => {
        setNotificationText('No fue posible actualizar tu dieta, por favor intenta mas tarde.')
      });


    }
  }

  return (
    <div className='accordion-container'>
      <Button
        id="request-new-diet-button"
        className='my-2 align-self-end'
        variant='outline-primary'
        onClick={reloadWeeklyDiet}
      >
        Cambiar Menú Semanal
      </Button>
      <Accordion activeKey={openAccordionDay} className='w-100'>
        {sortedDiet.length > 0 &&
          sortedDiet.map((dailyMeal: Meal[], index) => {
            return (
              <Accordion.Item
                eventKey={"dailyMeal-" + days[index]}
                key={"dailyMeal-" + index}
                className="diet-day"
                onClick={() => handleAccordionClick("dailyMeal-" + days[index])}
              >
                <Accordion.Header>
                  <span className="fw-bold">
                    {days[index]}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='w-100 py-2 d-flex flex-column align-items-center align-items-lg-end'>
                    <Button
                      variant="outline-secondary"
                      className="day-button d-flex align-items-center"
                      onClick={() => changeMenuByDay(days[index])}
                      disabled={isLoadingDay}
                      id={'change-meals-for-day-btn'}
                    >
                      Cambiar dieta del día
                      <ArrowRepeat size={24} className='mx-2' />
                    </Button>
                  </div>
                  <div className='d-flex flex-wrap justify-content-center align-items-center'>
                    {dailyMeal.map((meal: Meal, index) =>
                      <div key={meal.name + "-" + index} className='meal-card-container'>
                        {isLoadingDay || (selectedMeal === meal.name) ?
                          <Card className="loader-card meal-card mb-3">
                            <FadeLoader
                              color="#C0BCBC"
                              loading={true}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </Card>
                          :
                          <MealCard
                            meal={meal}
                            index={index}
                            day={meal.day}
                            requestMealOptions={requestMealOptions}
                            loadDiet={loadDiet}
                            showSubscriptionModal={() => setSubscriptionModal(true)}
                            currentUser={currentUser}
                            setOpenDietAccordionDay={setOpenDietAccordionDay}
                            setNotificationText={setNotificationText}
                          />
                        }
                      </div>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )
          })
        }
      </Accordion>

      <MealOptionsModal
        menuName={selectedMeal}
        day={selectedDay}
        show={showModal}
        mealOptions={mealOptions}
        selectMealOption={selectMealOption}
        onHide={onCloseModal}
      />
      <SubscriptionModal
        show={showSubscriptionModal}
        onClose={() => setSubscriptionModal(false)}
        onAccept={() => { setSubscriptionModal(false); toggleSubscriptionPlan(); }}
      />
      <SubscriptionPlanModal
        showModal={showSubscriptionPlanModal}
        subscription={currentUser?.subscription ?? null}
        onHide={toggleSubscriptionPlan}
      />
    </div>
  )
}


export default DietAccordion
